import { ReactNode } from "react"
import { KeyedMutator } from "swr"
import { useProjectWordPressSiteHealth } from "@components/Project/hooks/wordpress/useProjectWordPressSiteHealth"
import SiteHealthModel from "@/WordPressCore/models/SiteHealthModel"
import { JSONSiteHealthApiData } from "@/WordPressCore/types/api-types"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<{
    data: JSONSiteHealthApiData
    code: string
  }>
  isValidating: boolean
  isLoading: boolean
  data: SiteHealthModel
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  projectId: number
}

export function FetchOneProjectWordPressCoreData({
  projectId,
  children,
}: Props) {
  const response = useProjectWordPressSiteHealth({ projectId })

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
