import { createSchema } from "morphism"

export interface SourceLabels {
  id: string
  name: string
  description: string
  color: string
  created_at: string
  projects: {
    id: string
    name: string
    base_url: string
  }[]
  count_projects: number
}

export interface TargetLabelsModel {
  id: string
  name: string
  description: string
  color: string
  created_at: string
  projects: {
    id: number
    name: string
    base_url: string
  }[]
  count_projects: number
}

export const SchemaLabelsModel = createSchema<TargetLabelsModel, SourceLabels>({
  id: "id",
  name: "name",
  description: "description",
  color: "color",
  created_at: "created_at",
  projects: "projects",
  count_projects: "_count.projects",
})
