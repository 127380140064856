import { Label } from "@prisma/client"
import {
  BackupFrequency,
  Project,
  ProjectBackupSetting,
  ProjectEmailNotification,
  ProjectMaintenanceReportSetting,
  ScheduledBackup,
  ScheduledMaintenanceReport,
  UrlMonitor,
  User,
} from "@prisma/client"
import { format } from "date-fns"
import { get, isEmpty, isNil, omit } from "lodash"
import { createSchema, morphism } from "morphism"
import LabelsModel from "../Labels/model"
import { SchemaLabelsModel } from "../Labels/schemas"
import MaintenanceReportTemplateSingleModel, {
  SchemaMaintenanceReportTemplateSingleModel,
} from "../MaintenanceReportTemplates/MaintenanceReportTemplatesSingleModel"

export interface SourceSingleProjectExtended extends Project {
  ProjectEmailNotification?: ProjectEmailNotification[]
  ProjectBackupSetting?: ProjectBackupSetting
  ProjectMaintenanceReportSetting?: ProjectMaintenanceReportSetting
  User?: User
  UrlMonitor?: UrlMonitor[]
}

export interface SourceSingleProjectModel extends SourceSingleProjectExtended {
  total_backups_manual_authorized: number
  processes: {
    manage_plugins: Array<any>
  }
  next_maintenance_report: ScheduledMaintenanceReport | null
  next_backup: ScheduledBackup | null
  can_cache_clear?: boolean
  ProjectEmailNotification?: ProjectEmailNotification[]
  ProjectBackupSetting?: ProjectBackupSetting
  ProjectMaintenanceReportSetting?: ProjectMaintenanceReportSetting
  User?: User
  UrlMonitor?: UrlMonitor[]
  labels?: Label[]
}

export interface TargetSingleProjectModel {
  id: number
  base_url: string
  rest_url: string
  backdoor_url: string
  starred: boolean
  name: string
  userId: number
  hosting: string
  active_email_notify: boolean
  active_webhook_slack: boolean
  latest_downtime: number
  latest_ping: number
  latest_performance_score: number
  count_warnings: number
  webhook_slack: string
  user_id_wp_login: number | null
  default_request_wp_api: string | null
  created_at: string
  updated_at: string
  ProjectEmailNotification: Array<any>
  LighthouseHistory: Array<any>
  ProjectBackupSetting: {
    active: boolean
    exclude_files: any | null
    exclude_tables: any | null
    files: boolean
    frequency: BackupFrequency
    id: number
    sql: boolean
    process_version: string
  }
  maintenance_report_setting: ProjectMaintenanceReportSetting
  maintenance_report_setting_template: MaintenanceReportTemplateSingleModel
  total_backups_manual_authorized: number
  processes: {
    manage_plugins: Array<any>
  }
  next_maintenance_report: ScheduledMaintenanceReport | null
  next_backup: ScheduledBackup | null
  url_monitor_ids: number[]
  http_auth_user: string
  http_auth_password: string
  warnings: {
    wordpress_version: string
    php_current_version: string
  }
  labels: LabelsModel[]
  ProjectThirdPartyIntegration: any[]
}

export const SchemaSingleProjectModel = createSchema<
  TargetSingleProjectModel,
  SourceSingleProjectModel
>({
  id: "id",
  base_url: "base_url",
  rest_url: "rest_url",
  backdoor_url: "backdoor_url",
  name: "name",
  starred: "starred",
  userId: "userId",
  hosting: "hosting",
  active_email_notify: "active_email_notify",
  active_webhook_slack: "active_webhook_slack",
  latest_downtime: "latest_downtime",
  latest_ping: "latest_ping",
  latest_performance_score: "latest_performance_score",
  count_warnings: "count_warnings",
  webhook_slack: "webhook_slack",
  user_id_wp_login: "user_id_wp_login",
  default_request_wp_api: "default_request_wp_api",
  created_at: "created_at",
  updated_at: "updated_at",
  ProjectEmailNotification: "ProjectEmailNotification",
  LighthouseHistory: "LighthouseHistory",
  ProjectBackupSetting: "ProjectBackupSetting",
  ProjectThirdPartyIntegration: "ProjectThirdPartyIntegration",
  maintenance_report_setting: {
    path: "ProjectMaintenanceReportSetting",
    fn: (value) => {
      return omit(value, [
        "MaintenanceReportTemplate",
      ]) as ProjectMaintenanceReportSetting
    },
  },
  maintenance_report_setting_template: {
    path: "ProjectMaintenanceReportSetting.MaintenanceReportTemplate",
    fn: (value) => {
      return morphism(
        SchemaMaintenanceReportTemplateSingleModel,
        value,
        MaintenanceReportTemplateSingleModel,
      )
    },
  },
  total_backups_manual_authorized: "total_backups_manual_authorized",
  processes: "processes",
  next_maintenance_report: "next_maintenance_report",
  next_backup: "next_backup",
  http_auth_user: "http_auth_user",
  http_auth_password: "http_auth_password",
  url_monitor_ids: {
    path: "UrlMonitor",
    fn: (value) => {
      if (isNil(value) || isEmpty(value)) {
        return []
      }
      return value.map((monitor) => monitor.id)
    },
  },
  warnings: "warnings",
  labels: {
    path: "labels",
    fn: (value: Label[]) => {
      //@ts-ignore
      return morphism(SchemaLabelsModel, value, LabelsModel) as LabelsModel[]
    },
  },
})

class SingleProjectModel implements TargetSingleProjectModel {
  labels: LabelsModel[]
  warnings: { wordpress_version: string; php_current_version: string }
  LighthouseHistory: any[]
  id: number
  ProjectBackupSetting: {
    active: boolean
    exclude_files: any
    exclude_tables: any
    files: boolean
    frequency: BackupFrequency
    id: number
    sql: boolean
    process_version: string
  }
  ProjectEmailNotification: any[]
  ProjectThirdPartyIntegration: any[]
  active_email_notify: boolean
  active_webhook_slack: boolean
  starred: boolean
  backdoor_url: string
  base_url: string
  count_warnings: number
  created_at: string
  default_request_wp_api: string
  hosting: string
  latest_downtime: number
  latest_performance_score: number
  latest_ping: number
  maintenance_report_setting: ProjectMaintenanceReportSetting
  maintenance_report_setting_template: MaintenanceReportTemplateSingleModel
  name: string
  processes: { manage_plugins: any[] }
  rest_url: string
  total_backups_manual_authorized: number
  updated_at: string
  userId: number
  user_id_wp_login: number
  webhook_slack: string
  next_maintenance_report: ScheduledMaintenanceReport
  next_backup: ScheduledBackup
  url_monitor_ids: number[]
  http_auth_user: string
  http_auth_password: string

  public getHasSettingMaintenanceReport() {
    return !isNil(get(this, "maintenance_report_setting.id", null))
  }

  public hasTemplateMaintenanceReport() {
    return !isNil(
      get(this, "maintenance_report_setting.maintenanceReportTemplateId", null),
    )
  }

  public isLegacyMaintenanceReport() {
    return (
      this.hasTemplateMaintenanceReport() === false &&
      !isEmpty(this.maintenance_report_setting.email_to)
    )
  }

  public isScheduled(): boolean {
    return (
      !isNil(this.maintenance_report_setting) &&
      this.maintenance_report_setting.frequency !== null
    )
  }

  public getNextMaintenanceReportSchedule() {
    if (isNil(this.next_maintenance_report)) {
      return null
    }

    const nextDate = new Date(
      String(this.next_maintenance_report.date_schedule).replace("Z", ""),
    )
    nextDate.toLocaleString("en-GB", { timeZone: "UTC" })

    return `${format(nextDate, "dd MMMM yyyy")} at ${String(
      nextDate.getHours(),
    ).padStart(2, "0")}:${String(nextDate.getMinutes()).padStart(2, "0")} (UTC)`
  }

  public getNextBackupSchedule() {
    if (isNil(this.next_backup)) {
      return null
    }

    const nextDate = new Date(this.next_backup.date_schedule)

    return `${format(nextDate, "dd MMMM yyyy")} at ${format(nextDate, "HH:mm")}`
  }

  public getBackupSettingVersion() {
    return get(this, "ProjectBackupSetting.process_version", "old")
  }

  public canCacheClear() {
    return get(this, "can_cache_clear", false)
  }

  public getEmailsTo() {
    return get(this, "maintenance_report_setting.email_to", "")
      .split(";")
      .join(" - ")
  }
}
export default SingleProjectModel
