import { System } from "@design-system/index"
import { TableRowProcess } from "@design-system/Table/types"

import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { ProjectDatabaseOptimizationRow } from "./Row"
import { ProjectComponents } from "../.."

interface Props {
  projects: MainProjectsTableModel[]
  rowsProcessing?: Set<TableRowProcess>
}

const COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "posts",
    name: "Posts",
  },
  {
    key: "comments",
    name: "Comments",
  },
  {
    key: "transients",
    name: "Transients",
  },
  {
    key: "table",
    name: "Table",
  },
  {
    key: "action",
    name: "",
  },
]

export const TableProjectDatabaseOptimization = ({
  projects,
  rowsProcessing,
}: Props) => {
  return (
    <System.Table.List<MainProjectsTableModel>
      columns={COLUMNS}
      rows={projects}
      columnsClassname="grid-cols-[auto_3fr_1fr_1fr_1fr_1fr_1fr]"
      rowsProcessing={rowsProcessing}
      withChecked
      bulkActions={
        <ProjectComponents.BulkAction.DatabaseOptimization
          projects={projects}
        />
      }
    >
      {({ item, columnsClassname }) => {
        return (
          <>
            <ProjectDatabaseOptimizationRow
              project={item}
              columnsClassname={columnsClassname}
            />
          </>
        )
      }}
    </System.Table.List>
  )
}
