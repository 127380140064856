import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { System } from "@design-system/index"
import classNames from "classnames"

interface Props {
  project: MainProjectsTableModel
}

// Only for WordCamp Event
const FAVICONS_RANDOM_PROJECT_IDS = [6082, 19611, 38484, 16992, 43277, 49193]

function randomFavicon() {
  const RANDOM_URLS = [
    "https://wp-rocket.me/",
    "https://imagify.io/",
    "https://www.seopress.org/",
    "https://weglot.com/",
    "https://hellotravelersblog.com/",
    "https://www.novo-monde.com/",
    "https://wordpress.org/",
    "https://lesdroners.fr/",
    "https://oiseaurose.com/",
    "https://www.votretourdumonde.com/",
  ]

  return RANDOM_URLS[Math.floor(Math.random() * RANDOM_URLS.length)]
}

export const DataUptimeAndSite = ({ project }: Props) => {
  const baseUrl = FAVICONS_RANDOM_PROJECT_IDS.includes(project.id)
    ? randomFavicon()
    : project.base_url

  return (
    <div className="flex items-center gap-2">
      <div
        className={classNames(
          {
            "bg-green-200 text-green-800": !project.is_currently_down,
            "bg-red-200 text-red-800": project.is_currently_down,
          },
          "relative mr-2 rounded-full p-1 text-xs",
        )}
      >
        {project.is_currently_down ? (
          <>
            <System.Svg.TrendingDown className="h-3 w-3" />
            <span className="absolute left-0 top-0 inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75"></span>
          </>
        ) : (
          <>
            <System.Svg.TrendingUp className="h-3 w-3" />
          </>
        )}
      </div>
      <div className="">
        <System.Site.Favicon
          url={baseUrl}
          classNameContainer="rounded-full border border-gray-200"
        />
      </div>
      <div className="flex min-w-64 flex-col">
        <System.Link
          href="/projects/[idProject]"
          as={`/projects/${project.id}`}
          naked
          className="flex items-center text-sm font-medium text-indigo-600"
        >
          {project.starred && (
            <System.Svg.Star className="mr-1 h-4 w-4 fill-yellow-400 text-yellow-400/90" />
          )}
          {project.name.length > 35 ? (
            <span className="w-60 truncate">{project.name}</span>
          ) : (
            project.name
          )}
        </System.Link>
        <System.ExternalLink
          href={project.base_url}
          className="break-all text-xs text-gray-500"
        >
          {project.getUrl()}
        </System.ExternalLink>
      </div>
    </div>
  )
}
