interface Props {
  size?: "short" | "default"
  url: string
}
export const getProjectFormatUrl = (
  { url, size = "short" }: Props = {
    url: null,
    size: "short",
  },
) => {
  const objUrl = new URL(url)
  switch (size) {
    case "short":
      return objUrl.hostname + objUrl.pathname
    default:
      return url
  }
}
