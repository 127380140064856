import { createSchema } from "morphism"

import { JSONProjectThemeData } from "../types/api-types"
import { createEntityIdFromThemeProcess } from "@components/Theme/helpers/createEntityIdFromThemeProcess"
import ProjectThemeUpdateData from "./ProjectThemeUpdateData"
import { JSONProjectThemeApiData } from "../types/theme-models"
import { RuleType } from "@prisma/client"

export const SchemaJSONProjectThemeApiDataToProjectThemeModel = createSchema<
  Omit<ProjectThemeModel, "hasNeedUpdate" | "isIgnoredUpdate">,
  JSONProjectThemeData
>({
  id: (iterate) => {
    return createEntityIdFromThemeProcess({
      slug: iterate.stylesheet,
      projectId: iterate.projectId,
    })
  },
  is_active: "is_active",
  author: "author",
  author_uri: "author_uri",
  latest_version: "latest_version",
  name: "name",
  require_wp_version: "require_wp_version",
  require_php_version: "require_php_version",
  screenshot: "screenshot",
  stylesheet: "stylesheet",
  template: "template",
  theme_uri: "theme_uri",
  version: "version",
  created_at: "created_at",
  projectId: "projectId",
  id_theme: "id",
  ThemeRule: "ThemeRule",
  themeUpdateData: (data) => {
    return new ProjectThemeUpdateData(data)
  },
})

class ProjectThemeModel implements JSONProjectThemeApiData {
  ThemeRule: { type: RuleType }[]
  id: string // Use for table
  id_theme: string
  is_active: boolean
  author: string
  author_uri: string
  latest_version: string
  name: string
  require_wp_version: string
  require_php_version: string
  screenshot: string
  stylesheet: string
  template: string
  theme_uri: string
  version: string
  created_at: string
  projectId: number

  themeUpdateData: ProjectThemeUpdateData

  hasNeedUpdate(): boolean {
    return this.themeUpdateData.hasNeedUpdate()
  }

  isIgnoredUpdate(): boolean {
    return this.themeUpdateData.isIgnoredUpdate()
  }
}

export default ProjectThemeModel
