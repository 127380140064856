import { getMaintenanceReportTemplateProjectsSettingsPath } from "."

export interface Props {
  templateId: number
  data: any
}

export const editMaintenanceReportTemplateBulkSettings = async ({
  templateId,
  data,
}: Props) => {
  try {
    const result = await fetch(
      getMaintenanceReportTemplateProjectsSettingsPath({
        id: templateId,
      }),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
    )

    return await result.json()
  } catch (error) {
    return null
  }
}
