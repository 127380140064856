import { ReactNode } from "react"
import SingleProjectModel from "@/Core/Models/Projects/SingleProjectModel"
import useProject from "@hooks/projects/useProject"
import { SingleProjectDetailModelQuery } from "@/Core/Models/Projects/SingleProjectDetailModel"

interface PropsChildren {
  project: SingleProjectModel
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  options?: {
    refreshInterval?: number
  }
  params?: SingleProjectDetailModelQuery
  projectId: number
}

export function FetchOneProjectFromId({
  projectId,
  children,
  options,
  params,
}: Props) {
  const { data: project } = useProject({
    projectId: projectId,
    options: options,
    params: params,
  })

  return (
    <>
      {children({
        project: project,
      })}
    </>
  )
}
