import useNotifications from "@hooks/notifications/useNotifications"
import useApiKey from "hooks/user/useApiKey"
import { isNil } from "lodash"
import CopyToClipboard from "react-copy-to-clipboard"
import ContactSupportOrDocumentation from "../../ContactSupportOrDocumentation"
import PanelWarning from "../../Panel/Warning"
import { System } from "@design-system/index"
import {
  OVH_V4_IPS,
  OVH_V6_IPS,
  SCALEWAY_V4_IPS,
  SCALEWAY_V6_IPS,
  isOvhIp,
} from "@/Core/constants/output-ips"
import useProjectForceActivation from "@hooks/projects/useProjectForceActivation"

interface Props {
  codeError: string
  codeErrorDetail?: string // Code error from WordPress
  isCloudFlare?: boolean
  hasSecretToken?: boolean
  small?: boolean
  outputIp?: string
  viewIps?: string
  projectId: number
}

const KNOWN_ERROR_CODE = [
  "rest_no_route",
  "internal_server_error",
  "not_authorized",
  "not_authorize",
  "err_tls_cert_altname_invalid",
  "rest_forbidden",
  "not_synchronized",
]

const CantCommunicateWordPressApi = ({
  codeError,
  codeErrorDetail,
  isCloudFlare,
  hasSecretToken = true,
  small = false,
  outputIp = OVH_V4_IPS[0],
  projectId,
}: Props) => {
  const apiKey = useApiKey()

  const { handleNotifySuccess } = useNotifications()

  const codeErrorLower = codeError.toLowerCase()

  useProjectForceActivation({
    params: {
      projectId: projectId,
      inError: codeErrorDetail === "rest_no_route",
    },
  })

  const codeErrorDetailLower = isNil(codeErrorDetail)
    ? ""
    : codeErrorDetail.toLowerCase()

  if (!hasSecretToken) {
    return (
      <PanelWarning small={small}>
        <System.Text className="font-semibold">
          We can{`'`}t communicate with your WordPress site.
        </System.Text>
        <System.Text>
          Your secret token is missing!{" "}
          <ContactSupportOrDocumentation articleId="80">
            Learn how to troubleshoot this connection issue.
          </ContactSupportOrDocumentation>
        </System.Text>
      </PanelWarning>
    )
  }

  const ipv6 = isOvhIp(outputIp) ? OVH_V6_IPS[0] : SCALEWAY_V6_IPS[0]

  return (
    <PanelWarning small={small}>
      <System.Text className="font-semibold">
        We can{`'`}t communicate with your WordPress site.
      </System.Text>
      <System.Text>
        {(codeError === "rest_no_route" ||
          codeErrorDetailLower === "rest_no_route") &&
          !isCloudFlare && (
            <>
              The WP Umbrella plugin might have been deactivated. Reactivate the
              plugin to restore the connection.
            </>
          )}
        {codeErrorDetailLower === "internal_server_error" && !isCloudFlare && (
          <>
            PHP errors are occurring when we communicate with your site. Please
            check your server logs and{" "}
            <ContactSupportOrDocumentation>
              contact support
            </ContactSupportOrDocumentation>
            .
          </>
        )}
        {(codeErrorLower === "not_authorized" ||
          codeErrorLower === "not_authorize") &&
          !isCloudFlare && (
            <>
              The attempt to connect to your site fails because the configured
              options seem to be incorrect. You can{" "}
              <ContactSupportOrDocumentation>
                contact our support
              </ContactSupportOrDocumentation>{" "}
              for more information.
            </>
          )}
        {(codeErrorLower === "err_tls_cert_altname_invalid" ||
          codeErrorDetailLower === "err_tls_cert_altname_invalid") &&
          !isCloudFlare && (
            <>
              Your SSL certificate is not valid and prevents us from
              communicating with your site. Please fix the issue to connect your
              website to the dashboard.
            </>
          )}
        {(codeErrorLower === "rest_forbidden" ||
          codeErrorDetailLower === "rest_forbidden") &&
          !isCloudFlare && (
            <>
              Our security token seems to have expired. Your salts may have
              changed.{" "}
              <ContactSupportOrDocumentation articleId="80">
                Learn how to troubleshoot this connection issue.
              </ContactSupportOrDocumentation>
            </>
          )}
        {!KNOWN_ERROR_CODE.includes(codeErrorLower) &&
          !KNOWN_ERROR_CODE.includes(codeErrorDetail) &&
          !isCloudFlare && (
            <>
              Please make sure{" "}
              <CopyToClipboard
                text={apiKey}
                onCopy={() => {
                  handleNotifySuccess({
                    title: "API Key Copied!",
                    message: (
                      <>
                        The API key (
                        <strong>
                          {apiKey.substr(0, 10)}
                          ...
                        </strong>
                        ) is in your clipboard.
                      </>
                    ),
                  })
                }}
              >
                <span className="cursor-pointer text-indigo-600 underline">
                  your API Key
                </span>
              </CopyToClipboard>{" "}
              is connected. Feel free to{" "}
              <ContactSupportOrDocumentation>
                contact support
              </ContactSupportOrDocumentation>{" "}
              for more information or{" "}
              <ContactSupportOrDocumentation articleId="61069b26766e8844fc34c3ec">
                read the documentation
              </ContactSupportOrDocumentation>
              .
            </>
          )}
        {isCloudFlare && (
          <>
            To improve connectivity with <strong>Cloudflare</strong>, please
            whitelist our IPs: IPv4:{" "}
            <strong>
              {isOvhIp(outputIp) ? outputIp : SCALEWAY_V4_IPS.join(" - ")}
            </strong>{" "}
            and IPv6:
            <strong>{ipv6}</strong>. See our{" "}
            <ContactSupportOrDocumentation articleId="62140daa2130e51694689ccb">
              doc for more info
            </ContactSupportOrDocumentation>
            .
          </>
        )}
        {codeErrorLower === "not_synchronized" && (
          <>
            To improve connectivity with us, please whitelist our IPs: IPv4:{" "}
            <strong>
              {isOvhIp(outputIp) ? outputIp : SCALEWAY_V4_IPS.join(" - ")}
            </strong>{" "}
            and IPv6:
            <strong>{ipv6}</strong>. See our{" "}
            <ContactSupportOrDocumentation articleId="62140daa2130e51694689ccb">
              doc for more info
            </ContactSupportOrDocumentation>
            .
          </>
        )}
      </System.Text>
    </PanelWarning>
  )
}

export default CantCommunicateWordPressApi
