import { get } from "lodash"
import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class PluginVulnerabilities implements SpecificationWarningInterface {
  static KEY = "plugin_vulnerabilities"

  getKey(): string {
    return PluginVulnerabilities.KEY
  }

  getName(): string {
    return "Plugin Vulnerabilities"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return get(target, "vulnerabilities.plugins", []).length === 0
  }
}

export default PluginVulnerabilities
