import { System } from "@design-system/index"

import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { ProjectNotificationRow } from "./Row"
import { JSONOneAlertNotification } from "@/AlertingNotification/types/alert-notification-models"
import { ProjectComponents } from "../.."

interface Props {
  projects: MainProjectsTableModel[]
  notification: JSONOneAlertNotification
}

const COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "existing_notifications",
    name: "Existing Notifications",
  },
]

export const TableProjectNotification = ({ projects, notification }: Props) => {
  return (
    <System.Table.List<MainProjectsTableModel>
      columns={COLUMNS}
      rows={projects}
      columnsClassname="grid-cols-[auto_1fr_1fr]"
      withChecked
      bulkActions={
        <ProjectComponents.BulkAction.NotificationSitesSelector
          projects={projects}
          notification={notification}
        />
      }
    >
      {({ item, columnsClassname }) => {
        return (
          <>
            <ProjectNotificationRow
              project={item}
              columnsClassname={columnsClassname}
            />
          </>
        )
      }}
    </System.Table.List>
  )
}
