import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class WordPressIsUpToDate implements SpecificationWarningInterface {
  static KEY = "wp_is_up_to_date"

  getKey(): string {
    return WordPressIsUpToDate.KEY
  }

  getName(): string {
    return "WordPress Core Version"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return target.warnings.is_up_to_date
  }
}

export default WordPressIsUpToDate
