import { getMaintenanceReportTemplateDuplicatePath } from "."

export interface Props {
  templateId: number
}

export const duplicateMaintenanceReportTemplate = async (props: Props) => {
  try {
    const response = await fetch(
      getMaintenanceReportTemplateDuplicatePath({
        id: props.templateId,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      },
    )

    return await response.json()
  } catch (error) {
    console.log("[error duplicateMaintenanceReportTemplate]", error)
    return null
  }
}
