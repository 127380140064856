import { createSchema } from "morphism"
import { JSONProjectOnePhpIssueData } from "../types/api-types"
import { JSONProjectOnePhpIssueApiData } from "../types/php-issue-from-api"
import { ErrorType } from "@prisma/client"

export const SchemaJSONProjectOnePhpIssueApiDataToProjectOnePhpIssueModel =
  createSchema<ProjectOnePhpIssueModel, JSONProjectOnePhpIssueData>({
    id: "id",
    code: "code",
    file: "file",
    message: "message",
    date_last_php_issue: "PhpIssuesTimestamp[0].date_error",
    total_php_issue: "_count.PhpIssuesTimestamp",
    type_error: "type_error",
    slug: "slug",
    name: "name",
    line: "line",
    projectId: "projectId",
    author: "author",
    timestamp: "PhpIssuesTimestamp",
    title: "title",
    version: "version",
  })

class ProjectOnePhpIssueModel implements JSONProjectOnePhpIssueApiData {
  timestamp: {
    date_error: string
    php_version: string
    version: string
    wordpress_version: string
  }[]

  date_last_php_issue: string
  total_php_issue: number
  id: string
  code: number
  file: string
  message: string
  type_error: ErrorType
  slug: string
  name: string
  line: number
  projectId: number
  author: string
  title: string
  version: string
}

export default ProjectOnePhpIssueModel
