import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import qs from "query-string"
import {
  SchemaMainProjectsTableMeilisearch,
  SourceMainProjectsTableMeilisearch,
} from "@/Core/Models/Projects/schemas/meilisearch/SchemaMainProjectsTableMeilisearch"
import FilterContext from "@/Services/Search/FilterContext"
import { get, isNil } from "lodash"
import { morphism } from "morphism"
import useSWR from "swr"
import fetcherMeilisearch from "../../../helpers/fetcherMeilisearch"
import { MeilisearchIndexes } from "../../../src/Core/Meilisearch/MeilisearchIndexes"
import SortContext from "@/Services/Search/SortContext"
import SortCondition from "@/Services/Search/Meilisearch/SortCondition"

interface Props {
  search_user_token: string
  parameters?: {
    query?: string
    offset?: number
    limit?: number
    filter?: FilterContext
    sort?: SortContext
  }
}

export const useProjectsInstantSearch = ({
  search_user_token,
  parameters,
}: Props) => {
  let sort = null
  if (!isNil(parameters.sort)) {
    sort = new SortContext()
      .addCondition(
        new SortCondition({
          attribute: "starred",
          direction: "desc",
        }),
      )
      .addCondition(parameters.sort.getCondition(0))
  } else {
    sort = new SortContext()
      .addCondition(
        new SortCondition({
          attribute: "starred",
          direction: "desc",
        }),
      )
      .addCondition(
        new SortCondition({
          attribute: "name",
          direction: "asc",
        }),
      )
  }

  const url = qs.stringifyUrl({
    url: `/indexes/${MeilisearchIndexes.PROJECT}/search`,
    query: {
      q: parameters?.query,
      offset: parameters?.offset,
      limit: parameters?.limit,
      filter: parameters?.filter?.toString(),
      sort: sort.toString(),
    },
  })

  const { data, ...rest } = useSWR<{
    items: SourceMainProjectsTableMeilisearch[]
    total: number
    code: "success" | "error"
  }>(url, {
    fetcher: fetcherMeilisearch({
      searchToken: search_user_token,
    }),
    suspense: true,
  })

  if (isNil(data)) {
    return {
      data: {
        items: [] as MainProjectsTableModel[],
        total: 0,
        code: get(data, "code", "error"),
      },
      ...rest,
    }
  }

  const items = get(data, "items", [])

  return {
    data: {
      items: morphism(
        SchemaMainProjectsTableMeilisearch,
        items,
        MainProjectsTableModel,
      ),
      total: get(data, "total", 0),
      code: get(data, "code", "error"),
    },
    ...rest,
  }
}
