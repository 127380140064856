import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { System } from "@design-system/index"
import classNames from "classnames"

interface Props {
  project: MainProjectsTableModel
}
const DataPhpIssues = ({ project }: Props) => {
  return (
    <>
      <System.Link
        href="/projects/[idProject]/issues"
        as={`/projects/${project.id}/issues`}
        className={classNames(
          "group inline-flex cursor-pointer items-center gap-2 rounded-full bg-white pr-2 text-sm transition-all hover:bg-indigo-100",
        )}
      >
        <>
          <div
            className={classNames(
              "rounded-full bg-indigo-50 p-1 text-gray-800",
            )}
          >
            <System.Svg.Php className={classNames("h-4 w-4")} />
          </div>
          <System.Text>
            {project.getTotalPhpIssues()} Issue
            {project.getTotalPhpIssues() > 1 && "s"}
          </System.Text>
        </>
      </System.Link>
    </>
  )
}

export default DataPhpIssues
