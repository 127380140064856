import { ReactNode } from "react"

import { KeyedMutator } from "swr"
import useProjectRunningRestore from "@components/Project/hooks/useProjectRunningRestore"
import { JSONRestorationInProgressApiData } from "@/Restoration/types/restoration-in-progress-from-api"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<{
    code: string
    data: JSONRestorationInProgressApiData
  }>
  isValidating: boolean
  isLoading: boolean
  data: JSONRestorationInProgressApiData
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  projectId: number
}

export function FetchOneProjectCurrentRestoration({
  projectId,
  children,
}: Props) {
  const response = useProjectRunningRestore({
    projectId: projectId,
    options: {
      refreshInterval: 5000,
    },
  })

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
