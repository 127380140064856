import { getMaintenanceReportTemplatePath } from "."

export interface Props {
  templateId: number
}

export const deleteMaintenanceReportTemplate = async ({
  templateId,
}: Props) => {
  try {
    const result = await fetch(
      getMaintenanceReportTemplatePath({
        id: templateId,
      }),
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )

    return await result.json()
  } catch (error) {
    return null
  }
}
