import { useMeSearchUserToken } from "@components/Profile/hooks/useMeSearchUserToken"
import { System } from "@design-system/index"
import { useProjectsInstantSearch } from "@components/Project/hooks/useProjectsInstantSearch"
import { isEmpty } from "lodash"
import { useRouter } from "next/router"
import { Fragment, useEffect, useRef, useState } from "react"
import { Transition } from "@headlessui/react"

interface Props {
  currentProject: {
    id: number
    url: string
    name: string
  }
}

export const ProjectSelector = ({ currentProject }: Props) => {
  const { data } = useMeSearchUserToken()
  const { data: projects } = useProjectsInstantSearch({
    search_user_token: data?.search_user_token,
    parameters: {
      query: "",
      offset: 0,
      limit: 1000,
    },
  })

  const [open, setOpen] = useState(false)

  const [queryFilter, setQueryFilter] = useState<string>("")

  const getFilteringProjects = () => {
    if (isEmpty(projects.items)) {
      return []
    }

    return projects.items.filter((project) => {
      return (
        project.name.toLowerCase().includes(queryFilter.toLowerCase()) ||
        project.getUrl().toLowerCase().includes(queryFilter.toLowerCase())
      )
    })
  }

  const router = useRouter()
  const ref = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])

  return (
    <div className="relative">
      <div
        className="h-auto w-72 max-w-72 cursor-pointer rounded-md border-none p-2 text-left shadow-none ring-0 transition hover:bg-indigo-50 focus:ring-0"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className="flex items-center gap-2">
          <System.Site.Favicon
            url={currentProject.url}
            classNameContainer="rounded-full"
            className="h-7 w-7 rounded-full"
          />
          <div>
            <System.Text>
              <System.Strong>{currentProject.name}</System.Strong>
            </System.Text>
            <System.Text size="small">{currentProject.url}</System.Text>
          </div>
          <System.Svg.ChevronDown className="ml-auto h-4 w-4 opacity-50" />
        </div>
      </div>
      {!isEmpty(projects.items) && projects.items.length > 1 && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={open}
        >
          <div
            className="absolute left-0 top-0 z-50 mt-12 w-72 rounded-md border bg-white shadow"
            ref={ref}
          >
            <div className="absolute left-0 top-0 z-50 mb-2 w-full rounded-md bg-white p-2">
              <System.Form.Input
                placeholder="Search project"
                className="m-0 w-full"
                value={queryFilter}
                onChange={(e) => setQueryFilter(e.target.value)}
              />
            </div>
            <div className="relative z-40 mt-14 max-h-64 overflow-y-scroll px-1 pb-1">
              {getFilteringProjects().map((project) => {
                if (project.id === currentProject.id) {
                  return null
                }

                return (
                  <div
                    key={`project_${project.id}`}
                    className="rounded-md p-2 transition hover:bg-indigo-50"
                  >
                    <System.Link
                      naked
                      href={router.pathname.replace(
                        "[idProject]",
                        String(project.id),
                      )}
                    >
                      <div className="flex items-center gap-2">
                        <div className="min-w-7">
                          <System.Site.Favicon
                            url={project.base_url}
                            classNameContainer="rounded-full border border-gray-200"
                            className="h-5 w-5"
                          />
                        </div>
                        <div>
                          <System.Text>
                            <System.Strong>
                              {project.name.length > 22
                                ? `${project.name.substring(0, 22)}...`
                                : project.name}
                            </System.Strong>
                          </System.Text>
                          <System.Text size="small">
                            {project.getUrl().length > 28
                              ? `${project.getUrl().substring(0, 28)}...`
                              : project.getUrl()}
                          </System.Text>
                        </div>
                      </div>
                    </System.Link>
                  </div>
                )
              })}
            </div>
          </div>
        </Transition>
      )}
    </div>
  )
}
