import { getPrivateApiOneProjectSecurityPath } from "@/Project/api/routes-security-private"
import ProjectSecurityModel, {
  SchemaJSONSecurityApiDataToProjectSecurityModel,
} from "@/Project/models/ProjectSecurityModel"
import { JSONSecurityApiData } from "@/Project/types/project-security-from-api"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isNil } from "lodash"
import { morphism } from "morphism"
import qs from "query-string"
import useSWR from "swr"
import { BareFetcher, PublicConfiguration } from "swr/_internal"

interface Props {
  projectId: number
  options?: Partial<
    PublicConfiguration<
      {
        data: JSONSecurityApiData
        code: string
      },
      any,
      BareFetcher<{
        data: JSONSecurityApiData
        code: string
      }>
    >
  >
}

export function useProjectSecurityData({ projectId, options }: Props) {
  const url = qs.stringifyUrl({
    url: getPrivateApiOneProjectSecurityPath({
      projectId: projectId,
    }),
  })

  const { data: response, ...rest } = useSWR<{
    data: JSONSecurityApiData
    code: string
  }>(url, {
    ...options,
    suspense: isNil(options?.suspense) ? true : options.suspense,
    fetcher: fetcherPrivateApiUmbrella(),
  })

  const { data } = response

  return {
    data: morphism(
      SchemaJSONSecurityApiDataToProjectSecurityModel,
      data,
      ProjectSecurityModel,
    ),
    ...rest,
  }
}
