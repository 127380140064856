import { getProjectPath } from "../../src/FetchApi/Project"

interface Props {
  params: {
    projectId: number
    inError: boolean
  }
}

const useProjectForceActivation = ({ params }: Props) => {
  if (!params.inError) {
    return
  }

  fetch(`${getProjectPath(params.projectId)}/force-activate`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  })
}

export default useProjectForceActivation
