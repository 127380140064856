import classNames from "classnames"

import { STATUS } from "constants/status"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import SecurityComplianceCheck from "@/Security/Services/SecurityComplianceCheck"
import { SecurityComplianceCheckSpecifications } from "@/Security/Specifications"
import { isThemeInactive } from "@/Security/helpers/isThemeInactive"
import { get } from "lodash"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@design-system/Shadcn/ui/tooltip"
import { System } from "@design-system/index"

interface Props {
  project: MainProjectsTableModel
}

const securityComplianceCheckManager = new SecurityComplianceCheck(
  new SecurityComplianceCheckSpecifications["has_ssl"](),
  new SecurityComplianceCheckSpecifications["is_indexable"](),
  new SecurityComplianceCheckSpecifications["wp_is_up_to_date"](),
  new SecurityComplianceCheckSpecifications["php_version_is_supported"](),
  new SecurityComplianceCheckSpecifications["plugins_inactive"](),
  new SecurityComplianceCheckSpecifications["themes_inactive"](),
  new SecurityComplianceCheckSpecifications["wp_debug_is_defined"](),
  new SecurityComplianceCheckSpecifications["plugin_vulnerabilities"](),
  new SecurityComplianceCheckSpecifications["theme_vulnerabilities"](),
  new SecurityComplianceCheckSpecifications["wordpress_vulnerabilities"](),
)

const DataSiteHealth = ({ project }: Props) => {
  const getStatus = (total: number) => {
    if (total < 1) {
      return STATUS.GOOD
    }

    return STATUS.BAD
  }

  const activeTheme = project.getActiveTheme()

  const dataSecurityCheck = securityComplianceCheckManager
    .execute({
      inactive_plugins: project.plugins.filter((plugin) => {
        return !plugin.is_active
      }),
      inactive_themes: project.themes.filter((theme) => {
        return isThemeInactive({
          theme: theme,
          activeTheme: {
            template: get(activeTheme, "template"),
          },
        })
      }),
      vulnerabilities: project.vulnerabilities,
      warnings: {
        defined_wp_debug: project.warnings.defined_wp_debug,
        defined_wp_debug_display: false,
        defined_wp_debug_log: false,
        id: "0",
        is_indexable: project.warnings.is_indexable,
        is_ssl: project.warnings.is_ssl,
        is_up_to_date: project.warnings.is_up_to_date,
        php_current_version: project.warnings.php_current_version,
        php_is_secure: project.warnings.php_is_secure,
        php_is_supported: project.warnings.php_is_supported,
        php_recommended_version: project.warnings.php_recommended_version,
        wordpress_version: project.warnings.wordpress_version,
      },
    })
    .getData()

  const countCriticalWarnings = dataSecurityCheck.attention_needed.length

  const status = getStatus(countCriticalWarnings)

  return (
    <>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="animate flex items-center border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400">
              <System.Link
                href="/projects/[idProject]/security"
                as={`/projects/${project.id}/security`}
                className={classNames(
                  {
                    "hover:bg-green-100": status === STATUS.GOOD,
                    "hover:bg-red-200": status === STATUS.BAD,
                  },
                  "group inline-flex cursor-pointer items-center gap-2 rounded-full bg-white pr-2 text-sm transition-all",
                )}
              >
                <>
                  <div
                    className={classNames(
                      {
                        "bg-green-50": status === STATUS.GOOD,
                        "bg-red-50": status === STATUS.BAD,
                      },
                      "rounded-full p-1 text-gray-800",
                    )}
                  >
                    <System.Svg.Shield
                      className={classNames(
                        {
                          "text-green-500": status === STATUS.GOOD,
                          "text-red-500": status === STATUS.BAD,
                        },
                        "h-4 w-4",
                      )}
                    />
                  </div>
                  <System.Text>
                    {countCriticalWarnings} Risk
                    {countCriticalWarnings > 1 && "s"}
                  </System.Text>
                </>
              </System.Link>
            </div>
          </TooltipTrigger>
          {countCriticalWarnings > 0 && (
            <TooltipContent className="bg-slate-700 text-slate-50">
              {dataSecurityCheck.attention_needed.map((item, index) => {
                const itemClass = new SecurityComplianceCheckSpecifications[
                  item
                ]()
                return (
                  <div key={`site_health_${project.id}_${index}`}>
                    {itemClass.getName()}
                  </div>
                )
              })}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    </>
  )
}

export default DataSiteHealth
