import { createEntityIdFromPluginProcess } from "@components/Plugin/helpers/createEntityIdFromPluginProcess"
import { RuleType } from "@prisma/client"
import { isBoolean, isEmpty, isNil } from "lodash"
import { createSchema } from "morphism"
import {
  JSONProjectPluginData,
  JSONProjectPluginUpdate,
} from "../types/api-types"
import { JSONProjectPluginApiData } from "../types/plugin-from-api"

export const SchemaJSONProjectPluginApiDataToProjectPluginModel = createSchema<
  Omit<
    ProjectPluginModel,
    | "hasNeedUpdate"
    | "canBeActivated"
    | "canBeDeactivated"
    | "canBeSelectedForUpdate"
    | "isIgnoredUpdate"
    | "packageIsAvailable"
    | "hasProcessInProgress"
  >,
  JSONProjectPluginData
>({
  id: (iterate) => {
    return createEntityIdFromPluginProcess({
      slug: iterate.key,
      projectId: iterate.projectId,
    })
  },
  id_plugin: "id",
  name: "name",
  slug: "slug",
  is_active: "is_active",
  key: "key",
  version: "version",
  require_wp_version: "require_wp_version",
  require_php_version: "require_php_version",
  need_update: "need_update",
  is_premium: "is_premium",
  projectId: "projectId",
  PluginRule: "PluginRule",
  process_in_progress: "process_in_progress",
})

class ProjectPluginModel implements JSONProjectPluginApiData {
  is_premium: boolean
  PluginRule: { type: RuleType }[]
  id_plugin: string
  id: string
  name: string
  slug: string
  is_active: boolean
  key: string
  version: string
  require_wp_version: string
  require_php_version: string
  need_update: JSONProjectPluginUpdate
  projectId: number
  process_in_progress: boolean

  hasNeedUpdate(): boolean {
    if (!this.need_update || isEmpty(this.need_update)) {
      return false
    }

    return !isNil(this.need_update?.new_version)
  }

  canBeActivated(): boolean {
    return !this.is_active
  }
  canBeDeactivated(): boolean {
    if (this.key === "wp-health/wp-health.php") {
      return false
    }
    return this.is_active
  }

  packageIsAvailable(): boolean {
    // It could be a boolean because of YITH plugins
    if (isBoolean(this?.need_update?.package)) {
      return this?.need_update?.package
    }

    return (
      isNil(this?.need_update?.package) || !isEmpty(this?.need_update?.package)
    )
  }

  canBeSelectedForUpdate(): boolean {
    return this.packageIsAvailable() && !this.isIgnoredUpdate()
  }

  isIgnoredUpdate(): boolean {
    return (
      this.PluginRule.filter((rule) => rule.type === RuleType.IGNORE_UPDATE)
        .length > 0
    )
  }

  hasProcessInProgress(): boolean {
    return this.process_in_progress
  }
}

export default ProjectPluginModel
