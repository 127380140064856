import { get } from "lodash"

export interface SourceBaseProject {
  id: number
  is_multisite: boolean
  base_url: string
  name: string
  userId: number
  hosting: string
  latest_downtime: string
  latest_ping: number
  latest_performance_score: number
  count_warnings: number
  projectBackupSettingId: number
  projectMaintenanceReportSettingId: number
  projectFtpSettingId: number
  projectDatabaseSettingId: number
  maintenanceCustomerId: string
  is_currently_down: boolean
  backdoor_url: string
  http_auth_user: string
  http_auth_password: string
  active_email_notify: boolean
  active_webhook_slack: boolean
  webhook_slack: string
  created_at: string
  output_ip: string
}

class BaseProject implements SourceBaseProject {
  id: number
  is_multisite: boolean
  base_url: string
  wp_token: string
  name: string
  userId: number
  hosting: string
  latest_downtime: string
  latest_ping: number
  latest_performance_score: number
  count_warnings: number
  count_php_issues: number
  projectBackupSettingId: number
  projectMaintenanceReportSettingId: number
  projectFtpSettingId: number
  projectDatabaseSettingId: number
  maintenanceCustomerId: string
  is_currently_down: boolean
  backdoor_url: string
  http_auth_user: string
  http_auth_password: string
  active_email_notify: boolean
  active_webhook_slack: boolean
  webhook_slack: string
  created_at: string
  output_ip: string

  public getLatestPerformanceScore(): string {
    const score = get(this, "latest_performance_score", 0)
    return Number(score * 100).toFixed(0)
  }

  public getTotalPhpIssues(): number {
    return get(this, "count_php_issues", 0)
  }

  public getLatestPing(): string {
    const ping = get(this, "latest_ping", 0)
    return Number(ping).toFixed(0)
  }
}

export default BaseProject
