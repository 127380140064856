import { ReactNode } from "react"
import { KeyedMutator } from "swr"
import ProjectPluginModel from "@/Plugin/models/ProjectPluginModel"
import { JSONProjectPluginApiData } from "@/Plugin/types/plugin-from-api"
import { useProjectWordPressPlugins } from "@components/Project/hooks/wordpress/useProjectWordPressPlugins"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<{
    code: string
    data: JSONProjectPluginApiData[]
  }>
  isValidating: boolean
  isLoading: boolean
  data: ProjectPluginModel[]
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  projectId: number
  isImmutable?: boolean
}

export function FetchOneProjectPluginData({
  projectId,
  children,
  isImmutable = false,
}: Props) {
  const response = useProjectWordPressPlugins({
    projectId: projectId,
    isImmutable,
  })

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
