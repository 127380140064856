import { ProjectInProcessesFromByIds } from "@/Plugin/types/api-types"
import { System } from "@design-system/index"
import { TableRowProcess } from "@design-system/Table/types"
import { InProcessingRow } from "./Row"

const LABELS_COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "status",
    name: "Status",
  },
]

interface Props {
  projects: ProjectInProcessesFromByIds[]
  rowsProcessing: Set<TableRowProcess>
}

export type InProcessingInRowChildren = TableRowProcess & { id: string }

export type InProcessingItemRow = ProjectInProcessesFromByIds & {
  id: string
  children: InProcessingInRowChildren[]
}

export const InProcessing = ({ projects, rowsProcessing }: Props) => {
  return (
    <>
      <System.Table.List<InProcessingItemRow, InProcessingInRowChildren>
        columns={LABELS_COLUMNS}
        rows={projects.map((project) => {
          const subRows: TableRowProcess[] = Array.from(rowsProcessing).filter(
            (row) => {
              return row.task.projectId === project.id
            },
          )

          return {
            ...project,
            children: subRows.map((row) => {
              return {
                id: row.key,
                ...row,
              } as InProcessingInRowChildren
            }),
          } as InProcessingItemRow
        })}
        rowsProcessing={rowsProcessing}
        withExpanded
        withExpandCloseAll
      >
        {({ item, columns }) => {
          return <InProcessingRow item={item} columns={columns} />
        }}
      </System.Table.List>
    </>
  )
}
