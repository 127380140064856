import { getMaintenanceReportTemplatePath } from "."
import { isNil } from "lodash"
import { EditMaintenanceReportSettingProps } from "../../../components/Forms/MaintenanceReportTemplate/types/form-types"

export interface Props {
  templateId: number
  data: EditMaintenanceReportSettingProps
  type: "content" | "settings" | "schedule" | "email"
}

export const editMaintenanceReportTemplate = async ({
  templateId,
  data,
  type,
}: Props) => {
  const body = new FormData()

  Object.keys(data).forEach((key) => {
    const value = data[key]

    if (!isNil(value)) {
      body.append(key, value)
    }
  })

  if (type === "settings") {
    body.append("type", type)

    try {
      const result = await fetch(
        getMaintenanceReportTemplatePath({
          id: templateId,
        }),
        {
          method: "PUT",
          body: body,
        },
      )

      return await result.json()
    } catch (error) {
      return null
    }
  } else {
    try {
      const result = await fetch(
        getMaintenanceReportTemplatePath({
          id: templateId,
        }),
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
            type: type,
          }),
        },
      )

      return await result.json()
    } catch (error) {
      return null
    }
  }
}
