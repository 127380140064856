import { System } from "@design-system/index"
import classNames from "classnames"

interface Props {
  children: any
  small?: boolean
}

const PanelWarning = ({ children, small = false }: Props) => {
  return (
    <div
      className={classNames(
        {
          "p-2 py-4": !small,
          "p-1 py-2": small,
        },
        "rounded border border-yellow-200 bg-gradient-to-r from-yellow-100 via-yellow-50 to-yellow-100",
      )}
    >
      <div className="relative mx-4 flex items-start">
        <System.Svg.Alert className="mr-4 h-6 w-6 text-yellow-500" />
        <div>{children}</div>
      </div>
    </div>
  )
}

export default PanelWarning
