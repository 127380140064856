import { ReactNode } from "react"
import { KeyedMutator } from "swr"
import { JSONProjectOnePhpIssueData } from "@/PhpIssue/types/api-types"
import { useProjectOnePhpIssueData } from "@components/Project/hooks/useProjectOnePhpIssueData"
import ProjectOnePhpIssueModel from "@/PhpIssue/models/ProjectOnePhpIssueModel"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<JSONProjectOnePhpIssueData>
  isValidating: boolean
  isLoading: boolean
  data: ProjectOnePhpIssueModel
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  projectId: number
  isImmutable?: boolean
  phpIssueId: string
}

export function FetchOneProjectOnePhpIssueData({
  projectId,
  children,
  isImmutable = false,
  phpIssueId,
}: Props) {
  const response = useProjectOnePhpIssueData({
    projectId: projectId,
    phpIssueId: phpIssueId,
    isImmutable,
  })

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
