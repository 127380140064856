import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import qs from "query-string"
import {
  SchemaMainProjectsTableDatabase,
  SourceMainProjectsTableDatabase,
} from "@/Core/Models/Projects/schemas/database/SchemaMainProjectsTableDatabase"
import FilterContext from "@/Services/Search/FilterContext"
import SortContext from "@/Services/Search/SortContext"
import { get, isNil } from "lodash"
import { morphism } from "morphism"
import useSWR from "swr"
import { getProjectsPath } from "../../src/FetchApi/Project"

interface PropsUseProjects {
  needRequest?: boolean
  parameters?: {
    query?: string
    offset?: number
    limit?: number
    filter?: FilterContext
    sort?: SortContext
  }
}

export const useProjects = ({
  needRequest = true,
  parameters,
}: PropsUseProjects) => {
  const url = qs.stringifyUrl({
    url: getProjectsPath(),
    query: {
      q: parameters?.query,
      offset: parameters?.offset,
      limit: parameters?.limit,
      filter: parameters?.filter?.toString(),
      sort: parameters?.sort?.toString(),
    },
  })

  const { data, ...rest } = useSWR<{
    items: SourceMainProjectsTableDatabase[]
    total: number
  }>(needRequest ? url : null, {
    suspense: true,
  })

  if (isNil(data)) {
    return {
      data: {
        items: [] as MainProjectsTableModel[],
        total: 0,
      },
      ...rest,
    }
  }

  const items = get(data, "items", [])

  return {
    data: {
      items: morphism(
        SchemaMainProjectsTableDatabase,
        items,
        MainProjectsTableModel,
      ),
      total: get(data, "total", 0),
    },
    ...rest,
  }
}

export default useProjects
