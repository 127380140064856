import React from "react"

import DataPhpIssues from "./components/DataPhpIssues"
import { DataUptimeAndSite } from "./components/DataUptimeAndSite"
import DataPerformance from "./components/DataPerformance"
import classNames from "classnames"
import DataSiteHealth from "./components/DataSiteHealth"
import DataUpdates from "./components/DataUpdates"
import CantCommunicateWordPressApi from "@components/Messages/CantCommunicateWordPressApi"
import ErrorBoundary from "@components/ErrorBoundary"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { cn } from "@helpers/utils"
import { SystemConstant } from "@design-system/constants"
import { System } from "@design-system/index"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@design-system/Shadcn/ui/tooltip"
import { first, get, isEmpty, isNil } from "lodash"
import { formatDistanceToNow } from "date-fns"
import useResyncProjects from "@hooks/projects/useResyncProjects"

interface Props {
  project: MainProjectsTableModel
  index: number
}

const ActionsProject = ({ project }: { project: MainProjectsTableModel }) => {
  const frequency = project.getBackupFrequency()

  const lastSync = get(project, "last_synchronization.date", null)
  const lastBackup = get(project, "backup.last_backup", null)

  const { handleResyncData, processes } = useResyncProjects()

  const isInResync = () => {
    if (isEmpty(processes)) {
      return false
    }

    const process = first(processes)
    if (isNil(process)) {
      return false
    }

    if (process.tasks.length > 1) {
      return false
    }

    const taskForProject = process.tasks.find(
      (task) => task.projectId === project.id,
    )

    if (isNil(taskForProject)) {
      return false
    }

    return true
  }

  return (
    <div className="ml-auto flex items-center gap-2">
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger className="rounded-full bg-white p-1 text-sm group-hover:bg-slate-100">
            <System.Link
              naked
              href={`/projects/${project.id}/maintenance-reports`}
            >
              <System.Svg.Report
                className={classNames(
                  {
                    "h-5 w-5 text-gray-400 opacity-55":
                      !project.maintenanceReportTemplate,
                    "h-5 w-5 text-green-500": project.maintenanceReportTemplate,
                  },
                  "h-5 w-5",
                )}
              />
            </System.Link>
          </TooltipTrigger>
          <TooltipContent className="bg-slate-700 text-slate-50">
            {project.maintenanceReportTemplate ? (
              <p>Report activated</p>
            ) : (
              <p>Report not activated</p>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger className="rounded-full bg-white p-1 text-sm group-hover:bg-slate-100">
            <System.Link naked href={`/projects/${project.id}/backups`}>
              <System.Svg.Backup
                className={classNames(
                  {
                    "h-5 w-5 text-gray-400 opacity-55": isNil(frequency),
                    "h-5 w-5 text-green-500": !isNil(frequency),
                  },
                  "h-5 w-5",
                )}
              />
            </System.Link>
          </TooltipTrigger>
          <TooltipContent className="bg-slate-700 text-slate-50">
            {lastBackup ? (
              <p>
                Last backup available:{" "}
                {formatDistanceToNow(new Date(lastBackup))} ago
              </p>
            ) : (
              <p>No backup available</p>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <div className="group">
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger
              className={classNames(
                {
                  "animate-spin": isInResync(),
                },
                "rounded-full bg-white p-1 text-sm group-hover:bg-slate-100",
              )}
              onClick={() => {
                handleResyncData({
                  projectIds: [project.id],
                })
              }}
            >
              <System.Svg.Refresh className={classNames("h-5 w-5")} />
            </TooltipTrigger>
            {lastSync && (
              <TooltipContent className="bg-slate-700 text-slate-50">
                <p>
                  Last synchronization:{" "}
                  {formatDistanceToNow(new Date(lastSync))} ago
                </p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </div>
      <System.Button.Outlined
        href={`/projects/${project.id}/wordpress/login`}
        target="_blank"
        className="ml-4"
      >
        <System.Svg.WordPress className="mr-1 h-4 w-4" />
        WP Admin
      </System.Button.Outlined>
    </div>
  )
}

const TableLineProjectConnected = ({ project }: Props) => {
  return (
    <>
      <ErrorBoundary safeError={true}>
        <div className="flex items-center justify-center">
          <DataUpdates project={project} />
        </div>
      </ErrorBoundary>
      <div className="flex items-center justify-center">
        <DataSiteHealth project={project} />
      </div>
      <div className="flex items-center justify-center">
        <DataPhpIssues project={project} />
      </div>
      <ActionsProject project={project} />
    </>
  )
}

const FAVICONS_RANDOM_PROJECT_IDS = [6082, 19611, 38484, 16992, 43277]

export const MainRow = ({ project, index }: Props) => {
  return (
    <>
      <div
        className={classNames(
          "border-b border-indigo-600/10 py-5 odd:bg-gray-100/50 even:bg-white",
          {
            "border-t": index === 0,
          },
        )}
        key={index}
      >
        <div
          className={cn(
            "relative grid grid-cols-[2fr_1fr_1fr_1fr_1fr_auto] items-center gap-4",
            SystemConstant.PaddingX,
          )}
        >
          <DataUptimeAndSite project={project} />
          <div className="flex items-center justify-center">
            <DataPerformance project={project} />
          </div>

          {project.isCurrentlyNotSynchronized() ? (
            <div className="absolute right-0 mr-4 w-[70%]">
              <CantCommunicateWordPressApi
                small
                codeError="not_synchronized"
                outputIp={project.output_ip}
                projectId={project.id}
              />
            </div>
          ) : (
            <TableLineProjectConnected project={project} index={index} />
          )}
        </div>
      </div>
    </>
  )
}
