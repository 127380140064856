import {
  JSONPHPExtension,
  JSONSiteHealthApiData,
  JSONWordPressLatestData,
  JSONWordPressVersionInfo,
} from "@/WordPressCore/types/api-types"
import { createSchema } from "morphism"
import WordPressVersionInfo from "./WordPressVersionInfo"

export const SchemaJSONSiteHealthApiDataToSiteHealthModel = createSchema<
  Omit<
    SiteHealthModel,
    | "needWordPressCoreUpdate"
    | "getSqlVersionRecommandedByWordPress"
    | "getPhpVersionRecommandedByWordPress"
    | "getLatestWordPressVersion"
  >,
  JSONSiteHealthApiData
>({
  id: "id",
  defined_wp_debug: "defined_wp_debug",
  defined_wp_debug_log: "defined_wp_debug_log",
  defined_wp_debug_display: "defined_wp_debug_display",
  wordpress_version: "wordpress_version",
  is_indexable: "is_indexable",
  is_ssl: "is_ssl",
  is_up_to_date: "is_up_to_date",
  php_current_version: "php_current_version",
  php_recommended_version: "php_recommended_version",
  php_is_secure: "php_is_secure",
  php_is_supported: "php_is_supported",
  php_extensions: "php_extensions",
  latest: "latest",
  projectId: "projectId",
  wpVersionInformation: (data) => {
    return new WordPressVersionInfo(data)
  },
})

class SiteHealthModel implements JSONSiteHealthApiData {
  id: string
  defined_wp_debug: boolean
  defined_wp_debug_log: boolean
  defined_wp_debug_display: boolean
  wordpress_version: string
  is_indexable: boolean
  is_ssl: boolean
  is_up_to_date: boolean
  php_current_version: string
  php_recommended_version: string
  php_is_secure: boolean
  php_is_supported: boolean
  php_extensions: JSONPHPExtension[]
  latest?: JSONWordPressLatestData
  projectId: number
  wpVersionInformation: JSONWordPressVersionInfo

  needWordPressCoreUpdate(): boolean {
    return this.wpVersionInformation.needWordPressCoreUpdate()
  }
  getSqlVersionRecommandedByWordPress(): string {
    return this.wpVersionInformation.getSqlVersionRecommandedByWordPress()
  }
  getPhpVersionRecommandedByWordPress(): string {
    return this.wpVersionInformation.getPhpVersionRecommandedByWordPress()
  }
  getLatestWordPressVersion(): string {
    return this.wpVersionInformation.getLatestWordPressVersion()
  }
}

export default SiteHealthModel
