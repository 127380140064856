import {
  AttributesFilterConditionInterface,
  FilterConditionInterface,
} from "@/Services/Search/dependencies/FilterConditionInterface"
import FilterCondition from "./Meilisearch/FilterCondition"

class FilterConditionBuilder {
  static create({
    attribute,
    operator,
    value,
  }: AttributesFilterConditionInterface<any>): FilterConditionInterface {
    return new FilterCondition({ attribute, operator, value })
  }

  static createFromString(filterString: string): FilterCondition[] {
    const conditions: FilterCondition[] = []
    const filterPattern = /\(\s*(.+?)\s*\)/
    const conditionPattern = /([\w.]+)\s*=\s*"([^"]+)"/g

    const filterMatch = filterString.match(filterPattern)

    if (filterMatch && filterMatch[1]) {
      let conditionMatch

      while (
        (conditionMatch = conditionPattern.exec(filterMatch[1])) !== null
      ) {
        conditions.push(
          new FilterCondition({
            attribute: conditionMatch[1],
            operator: "=",
            value: conditionMatch[2],
          }),
        )
      }
    }

    return conditions
  }
}

export default FilterConditionBuilder
