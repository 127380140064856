import {
  SortDirection,
  SortConditionInterface,
} from "../dependencies/SortConditionInterface"

class SortCondition implements SortConditionInterface {
  attribute: string
  direction: SortDirection

  constructor({
    attribute,
    direction,
  }: {
    attribute: string
    direction: SortDirection
  }) {
    this.attribute = attribute
    this.direction = direction
  }

  toString(): string {
    return `${this.attribute}:${this.direction}`
  }
}

export default SortCondition
