import {
  FilterConditionInterface,
  OperatorFilterCondition,
} from "@/Services/Search/dependencies/FilterConditionInterface"

class FilterCondition implements FilterConditionInterface {
  attribute: string
  operator: OperatorFilterCondition
  value: string | number | boolean

  constructor({
    attribute,
    operator,
    value,
  }: {
    attribute: string
    operator: OperatorFilterCondition
    value: string | number | boolean
  }) {
    this.attribute = attribute
    this.operator = operator
    this.value = value
  }

  toString(): string {
    if (this.operator === "EXISTS") {
      return `${this.attribute} ${this.operator}`
    } else {
      let formattedValue: string

      if (typeof this.value === "string") {
        formattedValue = `"${this.value}"`
      } else {
        formattedValue = this.value.toString()
      }

      return `${this.attribute} ${this.operator} ${formattedValue}`
    }
  }
}

export default FilterCondition
