import SiteHealthModel, {
  SchemaSiteHealthModel,
} from "@/Core/Models/SiteHealth/database/SiteHealthModel"
import { getCountThemeNeedUpdate } from "@helpers/themes/getCountThemeNeedUpdate"
import {
  MaintenanceCustomer,
  NotificationEventType,
  ProjectBackupSetting,
  ProjectPlugins,
  ProjectThemes,
  ProjectWarnings,
} from "@prisma/client"
import { isNil, reduce } from "lodash"
import { createSchema, morphism } from "morphism"
import { TargetMainProjectsTable } from "../../MainProjectsTableModel"
import { SourceBaseProject } from "../../_BaseProject"

export interface SourceMainProjectsTableMeilisearch extends SourceBaseProject {
  plugins: ProjectPlugins[]
  themes: ProjectThemes[]
  warnings: ProjectWarnings
  vulnerabilities: {
    plugins: any[]
    themes: any[]
    wordpress: any[]
  }
  maintenanceCustomer: MaintenanceCustomer
  backup: {
    settings: ProjectBackupSetting
    next_backup: string
  }
  notification: {
    id: string
    emailNotificationChannelId: string
    slackNotificationChannelId: string
    eventTypes: NotificationEventType[]
  }[]
}

export const SchemaMainProjectsTableMeilisearch = createSchema<
  TargetMainProjectsTable,
  SourceMainProjectsTableMeilisearch
>({
  id: "id",
  is_multisite: "is_multisite",
  name: "name",
  base_url: "base_url",
  starred: "starred",
  wp_token: "wp_token",
  userId: "userId",
  hosting: "hosting",
  latest_downtime: "latest_downtime",
  latest_ping: "latest_ping",
  latest_performance_score: "latest_performance_score",
  count_warnings: "count_warnings",
  count_php_issues: "count_php_issues",
  created_at: "created_at",
  projectBackupSettingId: "projectBackupSettingId",
  projectMaintenanceReportSettingId: "projectMaintenanceReportSettingId",
  projectFtpSettingId: "projectFtpSettingId",
  projectDatabaseSettingId: "projectDatabaseSettingId",
  maintenanceCustomerId: "maintenanceCustomerId",
  maintenanceCustomer: "maintenanceCustomer",
  is_currently_down: "is_currently_down",
  plugins: "plugins",
  themes: "themes",
  labels: "labels",
  vulnerabilities: "vulnerabilities",
  last_synchronization: "last_synchronization",
  maintenanceReportTemplate: "maintenanceReportTemplate",
  output_ip: "output_ip",
  total_plugins_need_update: {
    path: "plugins",
    fn: (plugins: ProjectPlugins[]) => {
      return reduce(
        plugins,
        (acc, plugin) => {
          if (plugin.need_update) {
            acc += 1
          }
          return acc
        },
        0,
      )
    },
  },
  total_themes_need_update: {
    path: "themes",
    fn: (themes: ProjectThemes[]) => {
      return getCountThemeNeedUpdate({
        themes,
      })
    },
  },
  is_up_to_date: "warnings.is_up_to_date",
  //@ts-ignore
  warnings: {
    path: "warnings",
    fn: (warnings) => {
      if (isNil(warnings)) {
        //@ts-ignore
        return morphism(
          SchemaSiteHealthModel,
          {
            is_up_to_date: true,
            is_indexable: true,
            is_ssl: true,
            php_is_secure: true,
            php_is_supported: true,
            php_current_version: null,
            php_recommended_version: null,
            defined_wp_debug: false,
            total_db_optimization_expired_transient: null,
            total_db_optimization_table: null,
            total_db_optimization_spam_comment: null,
            total_db_optimization_trashed_comment: null,
            total_db_optimization_auto_draft_post: null,
            total_db_optimization_post_revision: null,
            total_db_optimization_trashed_post: null,
            wordpress_version: null,
          },
          SiteHealthModel,
        )
      }
      return morphism(SchemaSiteHealthModel, warnings, SiteHealthModel)
    },
  },
  backup: {
    path: "backup",
    fn: (backup) => {
      return {
        settings: backup.settings,
        next_schedule: backup.next_backup,
        last_backup: backup.last_backup,
      }
    },
  },

  notifications: {
    path: "notifications",
    fn: (notifications) => {
      if (isNil(notifications)) {
        return []
      }
      return notifications
    },
  },
})
