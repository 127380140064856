import { TableProjectDatabaseOptimization } from "./DatabaseOptimization"
import { InProcessing } from "./InProcessing"
import { Main } from "./Main"
import { TableProjectMaintenanceReportTemplate } from "./MaintenanceReportTemplate"
import { TableProjectNotification } from "./Notification"

export const ProjectsTable = {
  Main: Main,
  InProcessing: InProcessing,
  ProjectDatabaseOptimization: TableProjectDatabaseOptimization,
  ProjectMaintenanceReportTemplate: TableProjectMaintenanceReportTemplate,
  ProjectNotification: TableProjectNotification,
}
