import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class PluginsInactive implements SpecificationWarningInterface {
  static KEY = "plugins_inactive"

  getKey(): string {
    return PluginsInactive.KEY
  }

  getName(): string {
    return "Plugins Inactive"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return target.inactive_plugins.length === 0
  }
}

export default PluginsInactive
