import qs from "query-string"
import { isEmpty, isNil } from "lodash"
import useSWR from "swr"
import { BareFetcher, PublicConfiguration } from "swr/_internal"
import { morphism } from "morphism"
import RestorationInProgressModel, {
  SchemaJSONRestorationInProgressApiDataToRestorationInProgressModel,
} from "@/Restoration/models/api/RestorationInProgressModel"
import { JSONRestorationInProgressApiData } from "@/Restoration/types/restoration-in-progress-from-api"
import { getPrivateApiProjectBackupsRunningRestoreProcess } from "@/Project/api/routes-backup-private"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"

interface Props {
  projectId: number
  options?: Partial<
    PublicConfiguration<
      {
        code: string
        data: JSONRestorationInProgressApiData
      },
      any,
      BareFetcher<{
        code: string
        data: JSONRestorationInProgressApiData
      }>
    >
  >
}

function useProjectRunningRestore({ projectId, options }: Props) {
  const url = qs.stringifyUrl({
    url: getPrivateApiProjectBackupsRunningRestoreProcess({
      projectId: projectId,
    }),
  })

  const { data: response, ...rest } = useSWR<{
    code: string
    data: JSONRestorationInProgressApiData
  }>(url, {
    ...options,
    suspense: isNil(options?.suspense) ? true : options.suspense,
    fetcher: fetcherPrivateApiUmbrella(),
  })

  const { code, data } = response || {}

  if (code !== "success" || isNil(data) || isEmpty(data)) {
    return {
      data: null,
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONRestorationInProgressApiDataToRestorationInProgressModel,
      data,
      RestorationInProgressModel,
    ),
    ...rest,
  }
}

export default useProjectRunningRestore
