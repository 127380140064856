import { ProjectPlugins } from ".prisma/client"
import { countBy, isEmpty, reduce } from "lodash"

interface Props {
  plugins: Array<{ need_update: boolean | object } | ProjectPlugins>
}

export const getCountPluginNeedUpdate = ({ plugins }: Props) => {
  return reduce(
    plugins,
    (sum, item) => {
      if (item.need_update && !isEmpty(item.need_update)) {
        return sum + 1
      }
      return sum
    },
    0,
  )
}
