import SortCondition from "./Meilisearch/SortCondition"

class SortContext {
  conditions: SortCondition[]

  constructor(conditions: SortCondition[] = []) {
    this.conditions = conditions
  }

  addCondition(condition: SortCondition): SortContext {
    this.conditions.push(condition)
    return this
  }

  getConditions(): SortCondition[] {
    return this.conditions
  }

  getCondition(index: number): SortCondition {
    return this.conditions[index]
  }

  toStringArray(): string[] {
    const formattedConditions = this.conditions.map((condition) => {
      return condition.toString()
    })

    if (formattedConditions.length === 0) {
      return []
    }

    return formattedConditions
  }

  toString(): string {
    return this.toStringArray().join(",")
  }
}

export default SortContext
