import FilterCondition from "./Meilisearch/FilterCondition"

class FilterContext {
  conditions: FilterCondition[]

  constructor(conditions: FilterCondition[] = []) {
    this.conditions = conditions
  }

  getConditions(): FilterCondition[] {
    return this.conditions
  }

  addCondition(condition: FilterCondition): void {
    this.conditions.push(condition)
  }

  toStringArray(): string[] {
    if (this.conditions.length === 0) {
      return []
    }

    const groupedConditions: { [key: string]: FilterCondition[] } = {}
    this.conditions.forEach((condition) => {
      if (!groupedConditions[condition.attribute]) {
        groupedConditions[condition.attribute] = []
      }
      groupedConditions[condition.attribute].push(condition)
    })

    const formattedGroups = Object.keys(groupedConditions).map((attribute) => {
      const group = groupedConditions[attribute]
      const formattedGroup = group
        .map((condition) => condition.toString())
        .join(" OR ")
      return `(${formattedGroup})`
    })

    return formattedGroups.join(" AND ").split(" ")
  }

  toString(): string {
    return this.toStringArray().join(" ")
  }

  getCondition({ attribute, operator, value }: FilterCondition) {
    return this.conditions.find(
      (condition) =>
        condition.attribute === attribute &&
        condition.operator === operator &&
        condition.value === value,
    )
  }

  getConditionsByAttribute(attribute: string): FilterCondition[] {
    return this.conditions.filter(
      (condition) => condition.attribute === attribute,
    )
  }

  getConditionsByAttributeToArrayString(attribute: string) {
    const conditions = this.getConditionsByAttribute(attribute)

    if (conditions.length === 0) {
      return []
    }

    return conditions.map((condition) => condition.value)
  }
}

export default FilterContext
