import { System } from "@design-system/index"
import React, { useEffect } from "react"
import { TableColumn, TableRowProcess } from "@design-system/Table/types"
import { useTable } from "@design-system/Table/hooks/useTable"
import { InProcessingItemRow } from "../index"
import { PluginImage } from "@components/Plugin/components/PluginImage"
import { NewExternalLink } from "@ui/ExternalLink/NewExternalLink"
import { getDefaultClassNameText } from "@ui/System/Text"
import { SVG } from "@svg/index"
import classNames from "classnames"
import Loader from "@ui/Icons/Loader"
import { STRATEGY_INSTALL_PLUGIN } from "constants/bulk-install-plugins"

interface Props {
  item: InProcessingItemRow
  columns?: Array<TableColumn>
}

export const InProcessingRow = ({ item, columns = [] }: Props) => {
  const {
    isRowProcessing,
    getRowProcessing,
    toggleRowExpanded,
    state: { expandedRows },
  } = useTable()

  useEffect(() => {
    toggleRowExpanded({
      key: item.id,
    })
  }, [])

  const getTextInProcess = ({ item }: { item: TableRowProcess }) => {
    if (item.task.state === "waiting") {
      return "Processing..."
    }

    return "Installing..."
  }

  const allIsFinished = item.children.every((child) => {
    return child.task.finished_at !== null
  })

  return (
    <System.Table.Row
      key={`row_${item.id}`}
      item={item}
      className="grid-cols-[1fr_1fr]"
    >
      <div
        className={classNames(
          {
            "rounded-t-lg border-b": expandedRows.has(item.id),
            "rounded-lg": !expandedRows.has(item.id),
          },
          "sticky top-20 z-50 bg-white transition-all",
        )}
      >
        <System.Table.InnerRowWithActions
          rowKey={item.id}
          item={item}
          columns={columns}
          className="grid-cols-[auto_1fr_1fr]"
        >
          <System.Site.Preview
            site={{
              base_url: item.base_url,
              name: item.name,
            }}
          />
          {!allIsFinished && (
            <div className="flex items-center gap-2 text-sm">
              <Loader color="#4f46e5" />
              {item.children.length} Process in progress
            </div>
          )}
        </System.Table.InnerRowWithActions>
      </div>
      {expandedRows.has(item.id) && (
        <div className="flex flex-col divide-y divide-gray-100">
          {item.children.map((child) => {
            const name = child.task.entities.name
            const isInProcess = isRowProcessing({
              key: child.id,
            })

            let itemInProcess: TableRowProcess
            if (isInProcess) {
              itemInProcess = getRowProcessing({
                key: child.id,
              })
            }

            return (
              <React.Fragment key={child.id}>
                <System.Table.InnerRowChildren<TableRowProcess> item={child}>
                  <div className="flex items-center gap-4">
                    <PluginImage slug={child.task.entities.plugin} />
                    <div className="flex flex-col gap-1">
                      {child.task.entities.from ===
                        STRATEGY_INSTALL_PLUGIN.WORDPRESS_REPOSITORY && (
                        <>
                          <NewExternalLink
                            href={`https://wordpress.org/plugins/${child.task.entities.plugin}`}
                            className={getDefaultClassNameText({
                              className:
                                "inline-flex items-center gap-2 text-indigo-500 underline",
                            })}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  name.length > 35
                                    ? name.substr(0, 35) + "…"
                                    : name,
                              }}
                            ></span>
                            <SVG.ExternalLink className="h-4 w-4" />
                          </NewExternalLink>
                          <System.Text className="text-sm text-gray-500">
                            Version: {child.task.entities.version}
                          </System.Text>
                        </>
                      )}
                      {child.task.entities.from ==
                        STRATEGY_INSTALL_PLUGIN.URL && (
                        <>
                          <System.Text className="text-sm text-gray-500">
                            {child.task.entities.plugin_uri.length > 70
                              ? child.task.entities.plugin_uri.substr(0, 70) +
                                "…"
                              : child.task.entities.plugin_uri}
                          </System.Text>
                        </>
                      )}
                      {child.task.entities.from ==
                        STRATEGY_INSTALL_PLUGIN.ZIP && (
                        <>
                          <System.Text className="text-sm text-gray-500">
                            {child.task.entities.plugin_uri.split("/").pop()}
                          </System.Text>
                        </>
                      )}
                    </div>
                  </div>
                  {isInProcess && (
                    <div className="flex-1 px-8">
                      {itemInProcess.task.finished_at === null && (
                        <System.Table.CellInProgress
                          id={item.id}
                          task={itemInProcess.task}
                          text={getTextInProcess({
                            item: itemInProcess,
                          })}
                        />
                      )}
                      {itemInProcess.task.finished_at !== null &&
                        itemInProcess.task.code === "success" && (
                          <System.Table.CellProcessFinished
                            id={item.id}
                            text={"Installed!"}
                          />
                        )}
                      {itemInProcess.task.finished_at !== null &&
                        itemInProcess.task.code !== "success" && (
                          <System.Table.CellProcessFailed
                            id={item.id}
                            text={"Install failed!"}
                            projectId={item.id}
                            withOneClickAdmin
                          />
                        )}
                    </div>
                  )}
                </System.Table.InnerRowChildren>
              </React.Fragment>
            )
          })}
        </div>
      )}
    </System.Table.Row>
  )
}
