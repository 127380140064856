import versionCompare from "@helpers/versionCompare"
import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class PhpVersionIsSupported implements SpecificationWarningInterface {
  static KEY = "php_version_is_supported"

  getKey(): string {
    return PhpVersionIsSupported.KEY
  }

  getName(): string {
    return "PHP Version"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    if (
      target.warnings.php_is_secure &&
      target.warnings.php_is_supported &&
      versionCompare(
        target.warnings.php_current_version,
        target.warnings.php_recommended_version,
        ">=",
      )
    ) {
      return true
    }

    if (
      target.warnings.php_is_secure &&
      target.warnings.php_is_supported &&
      !versionCompare(
        target.warnings.php_current_version,
        target.warnings.php_recommended_version,
        "<",
      )
    ) {
      return false
    }

    if (!target.warnings.php_is_supported) {
      return false
    }

    return true
  }
}

export default PhpVersionIsSupported
