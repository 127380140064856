import { morphism } from "morphism"
import SingleProjectModel, {
  SchemaSingleProjectModel,
} from "../../Core/Models/Projects/SingleProjectModel"

function MorphSingleProject(useSWRNext) {
  return (key, fetcher, config) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const swr = useSWRNext(key, fetcher, config)
    return {
      ...swr,
      data: morphism(SchemaSingleProjectModel, swr.data, SingleProjectModel),
    }
  }
}

export default MorphSingleProject
