import { FilterProjectsAttributes } from "@/Services/Search/Types/FilterProjectsAttributes"
import { SortProjectsAttributes } from "@/Services/Search/Types/SortProjectsAttributes"
import { useFilters } from "@components/Filters/hooks/useFilters"
import { FilterProjectsContext } from "../contexts/FilterProjectsContext"

export function useFilterProjects() {
  return useFilters<FilterProjectsAttributes, SortProjectsAttributes>({
    context: FilterProjectsContext,
  })
}
