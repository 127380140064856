import { ReactNode } from "react"
import { KeyedMutator } from "swr"
import { useProjectPhpIssueData } from "@components/Project/hooks/useProjectPhpIssueData"
import ProjectPhpIssueModel from "@/PhpIssue/models/ProjectPhpIssueModel"
import { JSONProjectPhpIssueData } from "@/PhpIssue/types/api-types"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<{
    items: JSONProjectPhpIssueData[]
    total: string
  }>
  isValidating: boolean
  isLoading: boolean
  data: {
    items: ProjectPhpIssueModel[]
    total: string
  }
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  projectId: number
  isImmutable?: boolean
  filters: {
    type_error?: string
    page: number
    per_page: number
  }
}

export function FetchProjectPhpIssueData({
  projectId,
  children,
  isImmutable = false,
  filters,
}: Props) {
  const response = useProjectPhpIssueData({
    projectId: projectId,
    isImmutable,
    filters,
  })

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
