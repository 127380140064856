import { ReactNode } from "react"
import { useProjectSecurityData } from "@components/Project/hooks/useProjectSecurityData"
import ProjectSecurityModel from "@/Project/models/ProjectSecurityModel"
import { KeyedMutator } from "swr"
import { JSONSecurityApiData } from "@/Project/types/project-security-from-api"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<{
    data: JSONSecurityApiData
    code: string
  }>
  isValidating: boolean
  isLoading: boolean
  data: ProjectSecurityModel
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  projectId: number
}

export function FetchOneProjectSecurityData({ projectId, children }: Props) {
  const response = useProjectSecurityData({
    projectId: projectId,
  })

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
