import { ReactNode } from "react"
import SingleProjectModel from "@/Core/Models/Projects/SingleProjectModel"
import { useRouter } from "next/router"
import { SingleProjectDetailModelQuery } from "@/Core/Models/Projects/SingleProjectDetailModel"
import { FetchOneProjectFromId } from "../OneProjectFromId"

interface PropsChildren {
  project: SingleProjectModel
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  options?: {
    refreshInterval?: number
  }
  params?: SingleProjectDetailModelQuery
}
export function FetchOneProjectFromRouter({
  children,
  options,
  params,
}: Props) {
  const {
    query: { idProject },
  } = useRouter()

  return (
    <FetchOneProjectFromId
      projectId={Number(idProject)}
      params={params}
      options={options}
    >
      {({ project }) => {
        return (
          <>
            {children({
              project: project,
            })}
          </>
        )
      }}
    </FetchOneProjectFromId>
  )
}
