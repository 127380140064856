import useSWR from "swr"
import SingleProjectModel from "../../src/Core/Models/Projects/SingleProjectModel"
import { getProjectPath } from "../../src/FetchApi/Project"
import MorphSingleProject from "../../src/Middlewares/SWR/MorphSingleProject"
import { SingleProjectDetailModelQuery } from "../../src/Core/Models/Projects/SingleProjectDetailModel"

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
  projectId: number
  params?: SingleProjectDetailModelQuery
  morphism?: boolean
}

const useProject = ({
  suspense = true,
  options,
  projectId,
  params,
  morphism,
}: Props) => {
  const path = getProjectPath(projectId, params)

  const uses = []
  if (morphism) {
    uses.push(MorphSingleProject)
  }

  return useSWR<SingleProjectModel>(path, {
    suspense: suspense,
    ...options,
    use: uses,
  })
}

export default useProject
