import { TargetLabelsModel } from "./schemas"

class LabelsModel implements TargetLabelsModel {
  projects: {
    id: number
    name: string
    base_url: string
  }[]
  count_projects: number
  id: string
  name: string
  description: string
  color: string
  created_at: string
}

export default LabelsModel
