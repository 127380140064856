import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { System } from "@design-system/index"
import classNames from "classnames"

interface Props {
  project: MainProjectsTableModel
}

const DataPerformance = ({ project }: Props) => {
  const performanceGSpeed = project.getLatestPerformanceScore()

  return (
    <>
      <System.Link
        className={classNames(
          {
            "hover:bg-green-100": Number(performanceGSpeed) >= 90,
            "hover:bg-orange-100":
              Number(performanceGSpeed) < 90 && Number(performanceGSpeed) >= 78,
            "hover:bg-red-100": Number(performanceGSpeed) < 78,
          },
          "group relative inline-flex cursor-pointer items-center gap-2 rounded-full bg-white pr-2 text-sm transition-all",
        )}
        href="/projects/[idProject]"
        as={`/projects/${project.id}`}
      >
        <div className="rounded-full bg-white p-1.5 text-gray-800">
          <System.Svg.Google className="h-4 w-4" />
        </div>
        <System.Text>{performanceGSpeed}</System.Text>
        <System.Svg.Dot size={2} />
        <System.Text>{project.getLatestPing()}ms</System.Text>
      </System.Link>
    </>
  )
}

export default DataPerformance
