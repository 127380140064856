import ProjectOnePhpIssueModel, {
  SchemaJSONProjectOnePhpIssueApiDataToProjectOnePhpIssueModel,
} from "@/PhpIssue/models/ProjectOnePhpIssueModel"
import { JSONProjectOnePhpIssueData } from "@/PhpIssue/types/api-types"

import { getProjectOnePhpIssueDataPath } from "@/Project/api/routes"
import { isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import qs from "query-string"
import useSWR from "swr"
import { BareFetcher, PublicConfiguration } from "swr/_internal"
import useSWRImmutable from "swr/immutable"

interface Props {
  projectId: number
  phpIssueId: string
  options?: Partial<
    PublicConfiguration<
      JSONProjectOnePhpIssueData,
      any,
      BareFetcher<JSONProjectOnePhpIssueData>
    >
  >
  isImmutable?: boolean
}

export function useProjectOnePhpIssueData({
  projectId,
  phpIssueId,
  options,
  isImmutable = false,
}: Props) {
  const url = qs.stringifyUrl({
    url: getProjectOnePhpIssueDataPath({
      projectId: projectId,
      phpIssueId: phpIssueId,
    }),
  })

  const callback = isImmutable ? useSWRImmutable : useSWR

  const { data, ...rest } = callback<JSONProjectOnePhpIssueData>(url, {
    ...options,
    suspense: isNil(options?.suspense) ? true : options.suspense,
  })

  if (isNil(data) || isEmpty(data)) {
    return {
      data: null,
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONProjectOnePhpIssueApiDataToProjectOnePhpIssueModel,
      data,
      ProjectOnePhpIssueModel,
    ),
    ...rest,
  }
}
