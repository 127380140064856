import LabelLinkProjectModel from "@/Core/Models/Labels/Projects/model"
import useNotifications from "@hooks/notifications/useNotifications"
import { System } from "@design-system/index"
import { useTable } from "@design-system/Table/hooks/useTable"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { IBulkAction } from "@design-system/Content/BulkActions/List"
import FetchApiMaintenanceReportTemplates from "@/FetchApi/MaintenanceReportTemplates"
import MaintenanceReportTemplatesTableModel from "@/Core/Models/MaintenanceReportTemplates/MaintenanceReportTemplatesTableModel"
import { isNil } from "lodash"

interface Props {
  projects: LabelLinkProjectModel[] | MainProjectsTableModel[]
  template: MaintenanceReportTemplatesTableModel
  onSuccess?: () => void
}

export const MaintenanceReportTemplateSelector = ({
  projects,
  template,
  onSuccess,
}: Props) => {
  const { state } = useTable()
  const { openModal, closeModal } = useGenericModal()
  const { checkedRows } = state
  const { handleNotifySuccess } = useNotifications()

  const selectedProjects = Object.values(projects).filter((project) => {
    return checkedRows.has(project.id)
  })

  const handleLinkOnTemplate = async () => {
    closeModal()

    await FetchApiMaintenanceReportTemplates.linkProjectsMaintenanceReportTemplate(
      {
        templateId: template.id,
        projects: Array.from(checkedRows).map((id) => Number(id)),
      },
    )

    handleNotifySuccess({
      message: "Sites linked successfully",
    })

    if (!isNil(onSuccess)) {
      onSuccess()
    }
  }

  const handleUnlink = async () => {
    closeModal()

    await FetchApiMaintenanceReportTemplates.unlinkProjectsMaintenanceReportTemplate(
      {
        projects: Array.from(checkedRows).map((id) => Number(id)),
      },
    )

    handleNotifySuccess({
      message: "Sites unlinked successfully",
    })

    if (!isNil(onSuccess)) {
      onSuccess()
    }
  }

  const actions = [
    {
      label: "Assign to selection",
      icon: <System.Svg.Check className="h-4 w-4 opacity-50" />,
      onClick: () => {
        openModal({
          component: (
            <System.Modal.WithButtons
              title="Link on template"
              description={
                <>
                  Are you sure you want to link the template (
                  <System.Strong>{template.template_name}</System.Strong>) to
                  the selected projects?
                </>
              }
              onConfirm={handleLinkOnTemplate}
            />
          ),
        })
      },
    },
    {
      label: "Unassign to selection",
      icon: <System.Svg.Cross className="h-4 w-4 opacity-50" />,
      onClick: () => {
        openModal({
          component: (
            <System.Modal.WithButtons
              title="Unlink on template"
              description={
                <>
                  Are you sure you want to unlink the template (
                  <System.Strong>{template.template_name}</System.Strong>) to
                  the selected projects?
                </>
              }
              onConfirm={handleUnlink}
            />
          ),
        })
      },
      isDisabled() {
        return !selectedProjects.some((project) => {
          return !isNil(project.maintenanceReportTemplate)
        })
      },
    },
  ] as IBulkAction[]

  return (
    <>
      <System.Table.BulkActions.List
        actions={actions}
        isShowing={checkedRows.size > 0}
      />
    </>
  )
}

export default MaintenanceReportTemplateSelector
