import {
  MaintenanceReportFrequency,
  MaintenanceReportTypeFrequency,
} from "@prisma/client"
import { createSchema } from "morphism"
import { MaintenanceReportSection } from "../../../Constants/MaintenanceReportSections"
import MaintenanceReportTemplatesTableModel from "./MaintenanceReportTemplatesTableModel"

export interface SourceMaintenanceReportTemplateSingleModel {
  id: number
  title: string
  template_name: string
  color: string
  logo: string
  language: string
  email_to: string
  email_name: string
  email_subject: string
  email_from?: string
  email_body: string
  date_format: string
  time_format: string
  draft: boolean
  sections: Array<MaintenanceReportSection>
  sections_order: Array<string>
  schedule_start_date: string | null
  frequency: MaintenanceReportFrequency
  type_frequency: MaintenanceReportTypeFrequency
  date_frequency: number
  hour_frequency: string
  next_schedule: {
    start_date: string
    end_date: string
    date_schedule: string
  } | null
}

export interface TargetMaintenanceReportTemplateSingleModel {
  id: number
  title: string
  template_name: string
  color: string
  logo: string
  language: string
  email_to: string
  email_name: string
  email_subject: string
  email_from?: string
  email_body: string
  date_format: string
  time_format: string
  draft: boolean
  sections: Array<MaintenanceReportSection>
  sections_order: Array<string>
  schedule_start_date: string
  frequency: MaintenanceReportFrequency
  type_frequency: MaintenanceReportTypeFrequency
  date_frequency: number
  hour_frequency: string
  next_schedule: {
    start_date: string
    end_date: string
    date_schedule: string
  } | null
}

export const SchemaMaintenanceReportTemplateSingleModel = createSchema<
  TargetMaintenanceReportTemplateSingleModel,
  SourceMaintenanceReportTemplateSingleModel
>({
  color: "color",
  date_format: "date_format",
  time_format: "time_format",
  email_body: "email_body",
  email_name: "email_name",
  email_from: "email_from",
  email_subject: "email_subject",
  email_to: "email_to",
  language: "language",
  type_frequency: "type_frequency",
  frequency: "frequency",
  date_frequency: "date_frequency",
  hour_frequency: "hour_frequency",
  id: "id",
  schedule_start_date: "schedule_start_date",
  sections: "sections",
  sections_order: "sections_order",
  logo: "logo",
  title: "title",
  template_name: "template_name",
  draft: "draft",
  next_schedule: "next_schedule",
})

class MaintenanceReportTemplateSingleModel
  extends MaintenanceReportTemplatesTableModel
  implements TargetMaintenanceReportTemplateSingleModel
{
  type_frequency: MaintenanceReportTypeFrequency
  date_frequency: number
  hour_frequency: string
  color: string
  language: string
  email_to: string
  email_name: string
  email_subject: string
  email_from?: string
  email_body: string
  date_format: string
  time_format: string
  id: number
  title: string
  template_name: string
  logo: string
  draft: boolean
  sections: Array<MaintenanceReportSection>
  sections_order: Array<string>
  schedule_start_date: string
  frequency: MaintenanceReportFrequency
}

export default MaintenanceReportTemplateSingleModel
