import { isNil } from "lodash"

import { compareVersions } from "@helpers/compareVersions"
import { RuleType } from "@prisma/client"

export interface JSONProjectThemeUpdateData {
  version: string
  latest_version: string
  ThemeRule: {
    type: RuleType
  }[]
  hasNeedUpdate(): boolean
  isIgnoredUpdate(): boolean
}

class ProjectThemeUpdateData implements JSONProjectThemeUpdateData {
  version: string
  latest_version: string
  ThemeRule: { type: RuleType }[]
  constructor({
    version,
    latest_version,
    ThemeRule,
  }: {
    version: string
    latest_version: string
    ThemeRule: { type: RuleType }[]
  }) {
    this.version = version
    this.latest_version = latest_version
    this.ThemeRule = ThemeRule
  }

  hasNeedUpdate(): boolean {
    if (isNil(this.version) || isNil(this.latest_version)) {
      return false
    }

    if (
      this.version.split(".").length === 3 &&
      this.latest_version.split(".").length === 3
    ) {
      return compareVersions(this.version, this.latest_version) !== 0
    }

    if (this.version !== this.latest_version) {
      return true
    }

    return false
  }

  isIgnoredUpdate(): boolean {
    return (
      this.ThemeRule.filter((rule) => rule.type === RuleType.IGNORE_UPDATE)
        .length > 0
    )
  }
}

export default ProjectThemeUpdateData
