import { createSchema } from "morphism"
import {
  JSONProjectBackup,
  JSONRestorationInProgressApiData,
} from "@/Restoration/types/restoration-in-progress-from-api"
import {
  RestorationCommunicationMethod,
  RestorationRedisProcessContext,
} from "@/Restoration/types/types"
import { RestoreStatus, RestoreType } from "@prisma/client"

export const SchemaJSONRestorationInProgressApiDataToRestorationInProgressModel =
  createSchema<
    Omit<RestorationInProgressModel, "getStep">,
    JSONRestorationInProgressApiData
  >({
    code_error: "code_error",
    created_at: "created_at",
    error_message: "error_message",
    finished_at: "finished_at",
    id: "id",
    iterator: "iterator",
    parts: "parts",
    projectBackupId: "project_backup_id",
    read: "read",
    request_restore: "request_restore",
    restorationContext: "restoration_context",
    restorationId: "restoration_id",
    status: "status",
    status_restore_db: "status_restore_db",
    status_restore_file: "status_restore_file",
    type_restore_database: "type_restore_database",
    type_restore_file: "type_restore_file",
    ProjectBackup: "ProjectBackup",
    communicationMethod: "communication_method",
    progress: "progress",
  })

class RestorationInProgressModel implements JSONRestorationInProgressApiData {
  id: number
  request_restore: RestoreType[]
  status: RestoreStatus
  type_restore_file: null
  type_restore_database: null
  code_error: null
  error_message: null
  status_restore_file: null
  status_restore_db: null
  read: boolean
  projectBackupId: number
  created_at: string
  finished_at: null
  ProjectBackup: JSONProjectBackup
  restorationContext: "files" | "database"
  restorationId: number
  communicationMethod: RestorationCommunicationMethod
  parts: string[]
  iterator: number
  progress?: RestorationRedisProcessContext

  getStep() {
    return this.progress?.step
  }
}

export default RestorationInProgressModel
