import { getMaintenanceReportLinkProjectsPath } from "."
import qs from "query-string"

export interface Props {
  projects: number[]
}

export const unlinkProjectsMaintenanceReportTemplate = async ({
  projects,
}: Props) => {
  try {
    const url = qs.stringifyUrl({
      url: getMaintenanceReportLinkProjectsPath(),
      query: {
        projects: projects,
      },
    })

    const result = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })

    return await result.json()
  } catch (error) {
    return null
  }
}
