import { get } from "lodash"
import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class ThemeVulnerabilities implements SpecificationWarningInterface {
  static KEY = "theme_vulnerabilities"

  getKey(): string {
    return ThemeVulnerabilities.KEY
  }

  getName(): string {
    return "Theme Vulnerabilities"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return get(target, "vulnerabilities.themes", []).length === 0
  }
}

export default ThemeVulnerabilities
