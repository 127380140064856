import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class ThemesInactive implements SpecificationWarningInterface {
  static KEY = "themes_inactive"

  getKey(): string {
    return ThemesInactive.KEY
  }

  getName(): string {
    return "Themes Inactive"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return target.inactive_themes.length === 0
  }
}

export default ThemesInactive
