import { getMaintenanceReportLinkProjectPath } from "."

export interface Props {
  templateId: number
  projectId: number
}

export const toggleLinkProjectMaintenanceReportTemplate = async ({
  templateId,
  projectId,
}: Props) => {
  try {
    const result = await fetch(
      getMaintenanceReportLinkProjectPath({
        id: templateId,
        projectId,
      }),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId: projectId,
        }),
      },
    )

    return await result.json()
  } catch (error) {
    return null
  }
}
