import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class HasSSL implements SpecificationWarningInterface {
  static KEY = "has_ssl"

  getKey(): string {
    return HasSSL.KEY
  }

  getName(): string {
    return "SSL Issue"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return target.warnings.is_ssl
  }
}

export default HasSSL
