import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import EmptyDefault from "@components/Empty/EmptyDefault"
import { PaginationContext } from "@components/Pagination/contexts/PaginationContext"
import usePagination from "@components/Pagination/hooks/usePagination"
import { useFilterProjects } from "@components/Project/hooks/useFilterProjects"
import { SlideOverContext } from "@contexts/SlideOverContext"
import { FaceFrownIcon } from "@heroicons/react/24/outline"
import { useProjectsInstantSearch } from "@components/Project/hooks/useProjectsInstantSearch"

import { useProjects } from "@hooks/projects/useProjects"
import { SlideOverAddWebsite } from "@layouts/SlideOver/SlideOverAddWebsite"
import { System } from "@design-system/index"
import { useContext, useEffect } from "react"
import { isNil } from "lodash"
import { useMeSearchUserToken } from "@components/Profile/hooks/useMeSearchUserToken"

enum TypeSearch {
  MEILISEARCH = "MEILISEARCH",
  DATABASE = "DATABASE",
}

interface Props {
  children: (props: {
    projects: MainProjectsTableModel[]
    total: number
    type: TypeSearch
  }) => React.ReactNode
  perPage?: number
  offset?: number
}

export const ProjectsWithSearch = ({
  children,
  perPage: perPageProps,
  offset: offsetProps,
}: Props) => {
  const { data } = useMeSearchUserToken()

  const { actions } = useContext(PaginationContext)
  const { actions: actionsSlideOver } = useContext(SlideOverContext)

  const { state, clearFilterConditions, setSearchQuery, hasAtLeastOneFilter } =
    useFilterProjects()

  const { perPage, getOffset } = usePagination()

  const { data: projects } = useProjectsInstantSearch({
    search_user_token: data?.search_user_token,
    parameters: {
      query: state.search,
      filter: state.filters,
      offset: isNil(offsetProps) ? getOffset() : offsetProps,
      limit: isNil(perPageProps) ? perPage : perPageProps,
      sort: state.sort,
    },
  })

  const { data: fallbackProjects } = useProjects({
    needRequest: projects.code !== "success",
    parameters: {
      query: state.search,
      filter: state.filters,
      offset: isNil(offsetProps) ? getOffset() : offsetProps,
      limit: isNil(perPageProps) ? perPage : perPageProps,
      sort: state.sort,
    },
  })

  const getItems = () => {
    if (projects.code === "success") {
      return projects.items
    }

    return fallbackProjects.items
  }

  const getTotal = (): number => {
    if (projects.code === "success") {
      return Number(projects.total)
    }

    return Number(fallbackProjects.total)
  }

  useEffect(() => {
    actions.setTotal(getTotal())
  }, [projects, fallbackProjects])

  if (getTotal() === 0 && hasAtLeastOneFilter()) {
    return (
      <System.Container>
        <EmptyDefault>
          <FaceFrownIcon className="mx-auto h-12 w-12 text-gray-400" />
          <System.Text className="mt-2">
            We couldn't find any results. Please use the search bar or filter
            options to refine your search.
          </System.Text>
          <System.Button.Default
            size="small"
            className="mx-auto mt-8 underline"
            onClick={() => {
              clearFilterConditions()
              setSearchQuery("")
            }}
          >
            <System.Svg.FilterOff className="h-4 w-4 opacity-50" />
            Clear all filters
          </System.Button.Default>
        </EmptyDefault>
      </System.Container>
    )
  }

  if (getTotal() === 0 && !hasAtLeastOneFilter()) {
    return (
      <System.Content.Body withoutPaddingTop>
        <EmptyDefault>
          <FaceFrownIcon className="mx-auto h-12 w-12 text-gray-400" />
          <System.Text size="small" className="mt-2">
            You don't have any sites.
          </System.Text>
          <System.Button.Primary
            className="mr-4 mt-8"
            size="small"
            onClick={() => {
              actionsSlideOver.setData({
                title: "How To Add Websites To The Dashboard",
                description:
                  "You just need install WP Umbrella’s plugin and connect your API key! 🙂",
                component: <SlideOverAddWebsite />,
              })
            }}
          >
            <System.Svg.Plus className="h-4 w-4 opacity-50" />
            Add Site
          </System.Button.Primary>
        </EmptyDefault>
      </System.Content.Body>
    )
  }

  return (
    <>
      {children({
        type:
          projects.code === "success"
            ? TypeSearch.MEILISEARCH
            : TypeSearch.DATABASE,
        projects: getItems(),
        total: getTotal(),
      })}
    </>
  )
}
