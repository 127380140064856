import { MainRow } from "./MainRow"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"

interface Props {
  projects: MainProjectsTableModel[]
  total: number
}

export const Main = ({ projects }: Props) => {
  return (
    <>
      {projects.map((project, key: number) => {
        return (
          <MainRow
            key={`project_${project.id}`}
            index={key}
            project={project}
          />
        )
      })}
    </>
  )
}
