import { format } from "date-fns"
import { isEmpty, isNil } from "lodash"

interface Response {
  date_utc: string
  date_local: string
  timezone: string
  intl_format: string
}

export const getDateStringWithCurrentTimezone = ({
  date,
  dateFormat = "yyyy-MM-dd HH:mm:ss",
}: {
  date: string
  dateFormat?: string
}): Response => {
  if (isEmpty(date) || isNil(date)) {
    date = new Date().toISOString()
  }

  // New date UTC from string date
  const date_local = new Date(date)
  const date_utc = new Date(
    date_local.getTime() + date_local.getTimezoneOffset() * 60000,
  )

  let intlFormat = null
  try {
    intlFormat = Intl.DateTimeFormat(
      Intl.DateTimeFormat().resolvedOptions().locale,
      {
        timeZoneName: "short",
      },
    ).format(date_local)
  } catch (error) {}

  let timezone = null
  try {
    timezone = date_utc.toString().match(/([A-Z]+[\+-][0-9]+)/)[1]
  } catch (error) {}

  return {
    date_utc: format(date_utc, dateFormat),
    date_local: format(date_local, dateFormat),
    timezone: timezone,
    intl_format: intlFormat,
  }
}
