import fetch from "node-fetch"
import qs from "query-string"
import { first, get, isNil } from "lodash"
import Config from "@environments/data"

export const fetcherMeilisearch = ({ searchToken }: { searchToken: string }) =>
  async function (...args: any) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${searchToken}`,
    }

    const key = first(args)

    const path = qs.stringifyUrl({
      url: `${Config.meilisearch_public_url}${key}`,
    })

    try {
      const res = await fetch(path, {
        method: "GET",
        headers: headers,
      })
      const data = await res.json()

      if (!isNil(get(data, "code"))) {
        return {
          total: 0,
          items: [],
          code: "error" as "error",
        }
      }

      return {
        total: get(data, "estimatedTotalHits", 0),
        items: get(data, "hits", []),
        code: "success" as "success",
      }
    } catch (error) {
      return {
        total: 0,
        items: [],
        code: "error" as "error",
      }
    }
  }

export default fetcherMeilisearch
