import {
  MaintenanceReportFrequency,
  MaintenanceReportTypeFrequency,
} from "@prisma/client"
import { format, utcToZonedTime } from "date-fns-tz"
import { isNil } from "lodash"
import { createSchema } from "morphism"
import { MaintenanceReportSection } from "../../../Constants/MaintenanceReportSections"
import { getDateStringWithCurrentTimezone } from "../../../Helpers/getDateStringWithCurrentTimezone"

export interface SourceMaintenanceReportTemplatesTableModel {
  id: number
  title: string
  template_name: string
  draft: boolean
  sections: Array<MaintenanceReportSection>
  sections_order: Array<string>
  logo: string
  schedule_start_date: string | null
  frequency: MaintenanceReportFrequency
  type_frequency: MaintenanceReportTypeFrequency
  date_frequency: number
  hour_frequency: string
  active_projects: number
  next_schedule: {
    start_date: string
    end_date: string
    date_schedule: string
  } | null
}

export interface TargetMaintenanceReportTemplatesTableModel {
  id: number
  title: string
  template_name: string
  draft: boolean
  sections: Array<MaintenanceReportSection>
  sections_order: Array<string>
  schedule_start_date: string
  logo: string
  frequency: MaintenanceReportFrequency
  type_frequency: MaintenanceReportTypeFrequency
  date_frequency: number
  hour_frequency: string
  active_projects: number
  next_schedule: {
    start_date: string
    end_date: string
    date_schedule: string
  } | null
}

export const SchemaMaintenanceReportTemplatesTableModel = createSchema<
  TargetMaintenanceReportTemplatesTableModel,
  SourceMaintenanceReportTemplatesTableModel
>({
  frequency: "frequency",
  id: "id",
  schedule_start_date: "schedule_start_date",
  sections: "sections",
  sections_order: "sections_order",
  logo: "logo",
  title: "title",
  template_name: "template_name",
  draft: "draft",
  active_projects: "active_projects",
  next_schedule: "next_schedule",
  type_frequency: "type_frequency",
  date_frequency: "date_frequency",
  hour_frequency: "hour_frequency",
})

class MaintenanceReportTemplatesTableModel
  implements TargetMaintenanceReportTemplatesTableModel
{
  type_frequency: MaintenanceReportTypeFrequency
  date_frequency: number
  hour_frequency: string
  next_schedule: {
    start_date: string
    end_date: string
    date_schedule: string
  }
  id: number
  title: string
  template_name: string
  logo: string
  draft: boolean
  sections: Array<MaintenanceReportSection>
  sections_order: Array<string>
  schedule_start_date: string
  frequency: MaintenanceReportFrequency
  active_projects: number

  public isScheduled(): boolean {
    // return this.schedule_start_date !== null && this.frequency !== null;
    return this.frequency !== null
  }

  public getScheduledDate({
    dateFormat,
    timezone,
  }: {
    dateFormat: string
    timezone: string
  }): {
    date_utc: string
    date_local: string
    timezone: string
  } {
    // Legacy without timezone
    if (isNil(this.type_frequency)) {
      return getDateStringWithCurrentTimezone({
        date: this.schedule_start_date,
        dateFormat: dateFormat,
      })
    }

    if (isNil(this.next_schedule)) {
      return {
        date_utc: null,
        date_local: null,
        timezone: timezone,
      }
    }

    const date = new Date(this.next_schedule.date_schedule)
    const utc = format(utcToZonedTime(date, "UTC"), dateFormat, {
      timeZone: "UTC",
    })

    return {
      date_utc: utc,
      date_local: format(utcToZonedTime(date, timezone), dateFormat, {
        timeZone: timezone,
      }),
      timezone: timezone,
    }
  }

  public getScheduledHourFrequency(): string {
    try {
      return this.hour_frequency
    } catch (error) {
      return "09:00"
    }
  }
}

export default MaintenanceReportTemplatesTableModel
