import Config from "@environments/data"
import { concat } from "lodash"

export const OVH_V4_IPS = ["141.95.192.2"]
export const OVH_V6_IPS = ["2001:41d0:306:1702::/64"]

export const DEFAULT_IP_SCALEWAY = "212.83.142.5"

export const SCALEWAY_V4_IPS = [
  DEFAULT_IP_SCALEWAY,
  "212.83.175.107",
  "212.129.45.77",
]

export const SCALEWAY_V6_IPS = ["2001:bc8:2b7f:801::292/64"]

export const isOvhIp = (ip: string) => {
  return OVH_V4_IPS.includes(ip) || OVH_V6_IPS.includes(ip)
}

export const getPrivateApiByIp = (ip: string) => {
  if (isOvhIp(ip)) {
    return Config.private_api_url
  }

  return null
}

export const getV4Ips = () => {
  return concat(OVH_V4_IPS, SCALEWAY_V4_IPS)
}
