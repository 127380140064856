import classNames from "classnames"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { System } from "@design-system/index"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@design-system/Shadcn/ui/tooltip"

interface Props {
  project: MainProjectsTableModel
  live?: boolean
}

const DataUpdates = ({ project }: Props) => {
  const total = project.getTotalNeedUpdates()
  return (
    <>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="animate flex items-center border-b border-b-transparent text-sm group-hover:border-dashed group-hover:border-b-slate-400">
              <System.Link
                href="/projects/[idProject]/updates"
                as={`/projects/${project.id}/updates`}
                className={classNames(
                  {
                    "hover:bg-green-100": total <= 0,
                    "hover:bg-red-200": total > 0,
                  },
                  "group inline-flex cursor-pointer items-center gap-2 rounded-full bg-white pr-2 text-sm transition-all",
                )}
              >
                <>
                  <div
                    className={classNames(
                      {
                        "bg-green-50": total <= 0,
                        "bg-red-50": total > 0,
                      },
                      "rounded-full p-1 text-gray-800",
                    )}
                  >
                    <System.Svg.Update
                      className={classNames(
                        {
                          "text-green-500": total <= 0,
                          "text-red-500": total > 0,
                        },
                        "h-4 w-4",
                      )}
                    />
                  </div>
                  <System.Text>
                    {total === 0 ? (
                      "Up-to-date"
                    ) : (
                      <>
                        {total} Update
                        {total > 1 && `s`}
                      </>
                    )}
                  </System.Text>
                </>
              </System.Link>
            </div>
          </TooltipTrigger>
          {total > 0 && (
            <TooltipContent className="bg-slate-700 text-slate-50">
              <>
                {" "}
                {project.total_plugins_need_update > 0 && (
                  <div>
                    {project.total_plugins_need_update} Plugin
                    {project.total_plugins_need_update > 1 && "s"}
                  </div>
                )}
                {project.total_themes_need_update > 0 && (
                  <div>
                    {project.total_themes_need_update} Theme
                    {project.total_themes_need_update > 1 && "s"}
                  </div>
                )}
                {!project.is_up_to_date && <div>WordPress Core</div>}
              </>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    </>
  )
}

export default DataUpdates
