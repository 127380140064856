import { $Enums, BackupFrequency, NotificationEventType } from "@prisma/client"
import { differenceInHours, format } from "date-fns"
import { get, isEmpty, isNil } from "lodash"
import SiteHealthModel from "../SiteHealth/database/SiteHealthModel"
import BaseProject from "./_BaseProject"
import { getProjectFormatUrl } from "@components/Project/helpers/getProjectFormatUrl"

export interface TargetMainProjectsTable {
  id: number
  is_multisite: boolean
  base_url: string
  name: string
  wp_token: string
  userId: number
  hosting: string
  latest_downtime: string
  latest_ping: number
  latest_performance_score: number
  count_warnings: number
  starred: boolean
  count_php_issues: number
  created_at: string
  projectBackupSettingId: number
  projectMaintenanceReportSettingId: number
  projectFtpSettingId: number
  projectDatabaseSettingId: number
  maintenanceCustomerId: string
  is_currently_down: boolean
  vulnerabilities: {
    plugins: any[]
    themes: any[]
    wordpress: any[]
  }
  plugins: Array<{
    id: string
    key: string
    need_update: boolean
    is_active: boolean
    slug: string
    version: string
  }>
  themes: Array<{
    id: string
    stylesheet: string
    is_active: boolean
  }>
  total_plugins_need_update: number
  total_themes_need_update: number
  is_up_to_date: boolean
  warnings: SiteHealthModel
  labels?: Array<{
    id: number
    name: string
    color: string
  }>
  last_synchronization: {
    date: string
    date_with_success: string
  }
  maintenanceReportTemplate: {
    id: number
    template_name: string
  }
  maintenanceCustomer?: {
    id: string
    firstname: string
    lastname: string
    company_name: string
    website_url: string
  }
  backup: {
    settings: {
      active: boolean
      frequency: BackupFrequency
    }
    next_schedule: string
    last_backup: string
  }
  notifications: {
    id: string
    emailNotificationChannelId: string
    slackNotificationChannelId: string
    eventTypes: NotificationEventType[]
  }[]
  output_ip: string
}

class MainProjectsTableModel
  extends BaseProject
  implements TargetMainProjectsTable
{
  output_ip: string
  notifications: {
    id: string
    name?: string
    is_active?: boolean
    emailNotificationChannelId: string
    slackNotificationChannelId: string
    eventTypes: $Enums.NotificationEventType[]
  }[]
  starred: boolean
  plugins: {
    id: string
    key: string
    is_active: boolean
    need_update: boolean
    slug: string
    version: string
  }[]
  themes: {
    id: string
    stylesheet: string
    is_active: boolean
  }[]
  vulnerabilities: {
    plugins: any[]
    themes: any[]
    wordpress: any[]
  }
  total_plugins_need_update: number
  total_themes_need_update: number
  is_up_to_date: boolean
  warnings: SiteHealthModel
  backup: {
    settings: {
      active: boolean
      frequency: BackupFrequency
    }
    next_schedule: string
    last_backup: string
  }
  labels?: Array<{
    id: number
    name: string
    color: string
  }>
  last_synchronization: {
    date: string
    date_with_success: string
  }
  maintenanceReportTemplate: {
    id: number
    template_name: string
  }

  maintenanceCustomer?: {
    id: string
    firstname: string
    lastname: string
    company_name: string
    website_url: string
  }
  public getUrl(
    { size = "short" }: { size?: "short" | "default" } = {
      size: "short",
    },
  ) {
    return getProjectFormatUrl({
      url: this.base_url,
      size,
    })
  }

  public getTotalNeedUpdates() {
    return (
      this.total_plugins_need_update +
      this.total_themes_need_update +
      (this.is_up_to_date ? 0 : 1)
    )
  }

  public isBackupScheduled() {
    return !isNil(this.backup.next_schedule)
  }

  public getBackupNextSchedule() {
    if (!this.backup.next_schedule) {
      return null
    }

    try {
      return format(new Date(this.backup.next_schedule), "dd MMMM")
    } catch (error) {
      return null
    }
  }

  public isCurrentlyNotSynchronized() {
    try {
      if (
        this.last_synchronization.date_with_success ===
        this.last_synchronization.date
      ) {
        return false
      }

      const lastSyncWithSuccess = new Date(
        this.last_synchronization.date_with_success,
      )
      const now = new Date()

      // If the last synchronization with success is more than 9 hours ago and last sync less than 3 hours ago
      return differenceInHours(now, lastSyncWithSuccess) >= 9
    } catch (error) {
      return false
    }
  }

  public getBackupFrequency() {
    return get(this, "backup.settings.frequency")
  }

  public getActiveTheme() {
    return this.themes.find((theme) => {
      return theme.is_active
    })
  }

  public getUmbrellaVersion() {
    if (isEmpty(this.plugins)) {
      return null
    }

    const umbrellaPlugin = this.plugins.find(
      (plugin) => plugin.key === "wp-health/wp-health.php",
    )
    if (!umbrellaPlugin) {
      return null
    }

    return umbrellaPlugin.version
  }
}

export default MainProjectsTableModel
