import { JSONProjectPluginData } from "@/Plugin/types/api-types"
import { JSONWordPressEnvironment } from "@/WordPressCore/types/api-types"
import { SpecificationKeys } from "@/Security/types/specification-types"
import { JSONProjectThemeData } from "@/Theme/types/api-types"
import { addHours, differenceInMinutes, format } from "date-fns"
import { get, isNil } from "lodash"
import { createSchema } from "morphism"
import {
  JSONItemVulnerabilityData,
  JSONSecurityApiData,
} from "../types/project-security-from-api"

export const SchemaJSONSecurityApiDataToProjectSecurityModel = createSchema<
  Omit<
    ProjectSecurityModel,
    | "getLastAndNextScanString"
    | "getPluginsVulnerabilities"
    | "getThemesVulnerabilities"
    | "getWordPressVulnerabilities"
    | "hasPreventionData"
  >,
  JSONSecurityApiData
>({
  last_scan: "last_scan",
  security_prevention: "security_prevention",
  warnings: "warnings",
  vulnerabilities: "vulnerabilities",
  inactive_plugins: "inactive_plugins",
  inactive_themes: "inactive_themes",
  scan_in_progress: "scan_in_progress",
})

class ProjectSecurityModel implements JSONSecurityApiData {
  last_scan: string
  security_prevention: {
    passed: SpecificationKeys[]
    attention_needed: SpecificationKeys[]
  }
  warnings: JSONWordPressEnvironment
  vulnerabilities: {
    plugins: JSONItemVulnerabilityData[]
    themes: JSONItemVulnerabilityData[]
    wordpress: JSONItemVulnerabilityData[]
  }
  inactive_plugins: JSONProjectPluginData[]
  inactive_themes: JSONProjectThemeData[]
  scan_in_progress: boolean

  getPluginsVulnerabilities() {
    if (isNil(this.vulnerabilities)) {
      return []
    }
    return this.vulnerabilities.plugins.sort((a, b) => {
      return b.Vulnerability.cvss_score - a.Vulnerability.cvss_score
    })
  }

  getThemesVulnerabilities() {
    if (isNil(this.vulnerabilities)) {
      return []
    }
    return this.vulnerabilities.themes.sort((a, b) => {
      return b.Vulnerability.cvss_score - a.Vulnerability.cvss_score
    })
  }

  getWordPressVulnerabilities() {
    if (isNil(this.vulnerabilities)) {
      return []
    }
    return this.vulnerabilities.wordpress.sort((a, b) => {
      return b.Vulnerability.cvss_score - a.Vulnerability.cvss_score
    })
  }

  getLastAndNextScanString() {
    try {
      const lastScan = new Date(`${this.last_scan} UTC`)

      const nextScan = addHours(lastScan, 1)

      const diffMinutes = differenceInMinutes(nextScan, new Date())

      return {
        last_scan: format(lastScan, "MMM dd hh:mm a"),
        next_scan:
          diffMinutes < 2
            ? "few minutes"
            : `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""}`,
      }
    } catch (error) {
      return {
        last_scan: "Never",
        next_scan: "N/A",
      }
    }
  }

  hasPreventionData() {
    return (
      !isNil(get(this, "security_prevention.attention_needed")) &&
      !isNil(get(this, "security_prevention.passed"))
    )
  }
}

export default ProjectSecurityModel
