import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class IsIndexable implements SpecificationWarningInterface {
  static KEY = "is_indexable"

  getKey(): string {
    return IsIndexable.KEY
  }

  getName(): string {
    return "Indexable"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return target.warnings.is_indexable
  }
}

export default IsIndexable
