import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class SecurityComplianceCheck {
  protected specs: SpecificationWarningInterface[]

  protected attentionNeeded: string[] = []
  protected passed: string[] = []

  constructor(...specs: SpecificationWarningInterface[]) {
    this.specs = specs
  }

  execute(target: TypeSpecificationWarningInterface) {
    this.resetData()
    this.specs.map((spec) => {
      const isSatisfied = spec.isSatisfiedBy(target)
      if (isSatisfied) {
        this.passed.push(spec.getKey())
      } else {
        this.attentionNeeded.push(spec.getKey())
      }
    })
    return this
  }

  resetData() {
    this.attentionNeeded = []
    this.passed = []
  }

  getData() {
    return {
      passed: this.passed,
      attention_needed: this.attentionNeeded,
    }
  }

  countAttentionNeeded({ item }: { item: TypeSpecificationWarningInterface }) {
    this.resetData()
    this.execute(item)

    return this.attentionNeeded.length
  }
}

export default SecurityComplianceCheck
