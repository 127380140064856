import { System } from "@design-system/index"
import React from "react"
import { TableColumn } from "@design-system/Table/types"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"

interface Props {
  project: MainProjectsTableModel
  columns?: Array<TableColumn>
  columnsClassname?: string
}

export const ProjectMaintenanceReportTemplateRow = ({
  project,
  columns = [],
  columnsClassname,
}: Props) => {
  return (
    <System.Table.RowWithActions
      columns={columns}
      innerRowClassName={columnsClassname}
      rowKey={project.id}
      item={project}
    >
      <label
        htmlFor={`checkbox_${project.id}`}
        className="flex cursor-pointer items-center gap-4 truncate"
      >
        <System.Site.Preview
          site={{
            base_url: project.base_url,
            name: project.name,
          }}
        />
      </label>

      <div className="flex items-center gap-2">
        {project.maintenanceReportTemplate && (
          <System.Badges.Information>
            {project.maintenanceReportTemplate.template_name}
          </System.Badges.Information>
        )}
      </div>
    </System.Table.RowWithActions>
  )
}
