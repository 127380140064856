import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class WPDebugMode implements SpecificationWarningInterface {
  static KEY = "wp_debug_is_defined"

  getKey(): string {
    return WPDebugMode.KEY
  }

  getName(): string {
    return "Debug Mode Available"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return !target.warnings.defined_wp_debug
  }
}

export default WPDebugMode
