import { createMaintenanceReportTemplate } from "./createMaintenanceReportTemplate"
import { editMaintenanceReportTemplate } from "./editMaintenanceReportTemplate"
import { toggleLinkProjectMaintenanceReportTemplate } from "./toggleLinkProjectMaintenanceReportTemplate"
import { deleteMaintenanceReportTemplate } from "./deleteMaintenanceReportTemplate"
import { editMaintenanceReportTemplateBulkSettings } from "./editMaintenanceReportTemplateBulkSettings"
import { linkProjectsMaintenanceReportTemplate } from "./linkProjectsMaintenanceReportTemplate"
import { unlinkProjectsMaintenanceReportTemplate } from "./unlinkProjectsMaintenanceReportTemplate"
import { deleteMaintenanceReportTemplates } from "./deleteMaintenanceReportTemplates"
import { duplicateMaintenanceReportTemplate } from "./duplicateMaintenanceReportTemplate"

export const getMaintenanceReportTemplatesPath = (): string => {
  return `/api/maintenance-report-templates`
}

/**
 * /api/maintenance-report-templates/[templateId]
 */
export const getMaintenanceReportTemplatePath = ({
  id,
}: {
  id: number
}): string => {
  return `${getMaintenanceReportTemplatesPath()}/${id}`
}

/**
 * /api/maintenance-report-templates/[templateId]/duplicate
 */
export const getMaintenanceReportTemplateDuplicatePath = ({
  id,
}: {
  id: number
}): string => {
  return `${getMaintenanceReportTemplatePath({
    id,
  })}/duplicate`
}

/**
 * /api/maintenance-report-templates/[templateId]/pdf-data
 */
export const getMaintenanceReportTemplatePdfDataPath = ({
  id,
}: {
  id: number
}): string => {
  return `${getMaintenanceReportTemplatePath({
    id,
  })}/pdf-data`
}

/**
 * /api/maintenance-report-templates/projects
 */
export const getMaintenanceReportLinkProjectsPath = () => {
  return `${getMaintenanceReportTemplatesPath()}/projects`
}

/**
 * /api/maintenance-report-templates/[templateId]/projects
 */
export const getMaintenanceReportLinkProjectsByTemplatePath = ({
  id,
}: {
  id: number
}) => {
  return `${getMaintenanceReportTemplatePath({
    id,
  })}/projects`
}

/**
 * /api/maintenance-report-templates/[templateId]/projects/bulk-settings
 */
export const getMaintenanceReportTemplateProjectsSettingsPath = ({
  id,
}: {
  id: number
}): string => {
  return `${getMaintenanceReportLinkProjectsByTemplatePath({
    id,
  })}/bulk-settings`
}

/**
 * /api/maintenance-report-templates/[templateId]/projects/[projectId]/bulk-settings
 */
export const getMaintenanceReportTemplateSingleProjectSettingsPath = ({
  id,
  projectId,
}: {
  id: number
  projectId: number
}): string => {
  return `${getMaintenanceReportLinkProjectsByTemplatePath({
    id,
  })}/${projectId}/bulk-settings`
}

/**
 * /api/maintenance-report-templates/[templateId]/projects/[projectId]
 */
export const getMaintenanceReportLinkProjectPath = ({
  id,
  projectId,
}: {
  id: number
  projectId: number
}) => {
  return `${getMaintenanceReportLinkProjectsByTemplatePath({
    id,
  })}/${projectId}`
}

const FetchApiMaintenanceReportTemplates = {
  createMaintenanceReportTemplate,
  editMaintenanceReportTemplate,
  toggleLinkProjectMaintenanceReportTemplate,
  deleteMaintenanceReportTemplate,
  editMaintenanceReportTemplateBulkSettings,
  linkProjectsMaintenanceReportTemplate,
  unlinkProjectsMaintenanceReportTemplate,
  deleteMaintenanceReportTemplates,
  duplicateMaintenanceReportTemplate,
}

export default FetchApiMaintenanceReportTemplates
