import { get } from "lodash"
import {
  SpecificationWarningInterface,
  TypeSpecificationWarningInterface,
} from "../types/specification-types"

class WordPressVulnerabilities implements SpecificationWarningInterface {
  static KEY = "wordpress_vulnerabilities"

  getKey(): string {
    return WordPressVulnerabilities.KEY
  }

  getName(): string {
    return "WordPress Vulnerabilities"
  }

  isSatisfiedBy(target: TypeSpecificationWarningInterface) {
    return get(target, "vulnerabilities.wordpress", []).length === 0
  }
}

export default WordPressVulnerabilities
