import ProjectPhpIssueModel, {
  SchemaJSONProjectPhpIssueApiDataToProjectPhpIssueModel,
} from "@/PhpIssue/models/ProjectPhpIssueModel"
import { JSONProjectPhpIssueData } from "@/PhpIssue/types/api-types"

import { getProjectPhpIssueDataPath } from "@/Project/api/routes"
import { isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import qs from "query-string"
import useSWR from "swr"
import { BareFetcher, PublicConfiguration } from "swr/_internal"
import useSWRImmutable from "swr/immutable"

type Request = {
  items: JSONProjectPhpIssueData[]
  total: string
}

interface Props {
  projectId: number
  options?: Partial<PublicConfiguration<Request, any, BareFetcher<Request>>>
  isImmutable?: boolean
  filters: {
    type_error?: string
    page: number
    per_page: number
  }
}

export function useProjectPhpIssueData({
  projectId,
  options,
  isImmutable = false,
  filters,
}: Props) {
  const url = qs.stringifyUrl({
    url: getProjectPhpIssueDataPath({
      projectId: projectId,
    }),
    query: filters,
  })

  const callback = isImmutable ? useSWRImmutable : useSWR

  const { data, ...rest } = callback<Request>(url, {
    ...options,
    suspense: isNil(options?.suspense) ? true : options.suspense,
  })

  if (isNil(data) || isEmpty(data)) {
    return {
      data: {
        items: [],
        total: "0",
      },
      ...rest,
    }
  }

  return {
    data: {
      items: morphism(
        SchemaJSONProjectPhpIssueApiDataToProjectPhpIssueModel,
        data.items,
        ProjectPhpIssueModel,
      ),
      total: data.total,
    },
    ...rest,
  }
}
