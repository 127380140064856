import { ProjectsWithSearch } from "./ProjectsWithSearch"
import { FetchOneProjectFromRouter } from "./OneProjectFromRouter"
import { FetchOneProjectFromId } from "./OneProjectFromId"
import { FetchOneProjectSecurityData } from "./OneProjectSecurityData"
import { FetchOneProjectPluginData } from "./OneProjectPluginData"
import { FetchProjectPhpIssueData } from "./OneProjectPhpIssueData"
import { FetchOneProjectOnePhpIssueData } from "./OneProjectOnePhpIssueData"
import { FetchOneProjectCurrentRestoration } from "./OneProjectCurrentRestoration"
import { FetchOneProjectWordPressCoreData } from "@components/Project/components/Fetch/OneProjectWordPressCoreData"
import { FetchOneProjectThemeData } from "./OneProjectThemeData"

export const FetchManyProjects = {
  WithSearch: ProjectsWithSearch,
}

export const FetchOneProject = {
  FromRouter: FetchOneProjectFromRouter,
  FromId: FetchOneProjectFromId,
  SecurityData: FetchOneProjectSecurityData,
  PluginData: FetchOneProjectPluginData,
  ThemeData: FetchOneProjectThemeData,
  WordPressCoreData: FetchOneProjectWordPressCoreData,
  PhpIssueData: FetchProjectPhpIssueData,
  OnePhpIssueData: FetchOneProjectOnePhpIssueData,
  CurrentRestoration: FetchOneProjectCurrentRestoration,
}
