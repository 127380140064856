import { createSchema } from "morphism"
import { JSONProjectPhpIssueData } from "../types/api-types"
import { JSONProjectPhpIssueApiData } from "../types/php-issue-from-api"
import { $Enums } from "@prisma/client"

export const SchemaJSONProjectPhpIssueApiDataToProjectPhpIssueModel =
  createSchema<ProjectPhpIssueModel, JSONProjectPhpIssueData>({
    id: "id",
    code: "code",
    file: "file",
    message: "message",
    date_last_php_issue: "PhpIssuesTimestamp[0].date_error",
    total_php_issue: "_count.PhpIssuesTimestamp",
    type_error: "type_error",
    slug: "slug",
    name: "name",
    line: "line",
    projectId: "projectId",
  })

class ProjectPhpIssueModel implements JSONProjectPhpIssueApiData {
  projectId: number
  line: string
  name: string
  slug: string
  type_error: $Enums.ErrorType
  date_last_php_issue: string
  total_php_issue: number
  code: number
  file: string
  message: string
  id: string
}

export default ProjectPhpIssueModel
