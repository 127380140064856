import {
  AttributesSortConditionInterface,
  SortConditionInterface,
  SortDirection,
} from "./dependencies/SortConditionInterface"
import SortCondition from "./Meilisearch/SortCondition"

class SortConditionBuilder {
  static create({
    attribute,
    direction,
  }: AttributesSortConditionInterface<any>): SortConditionInterface {
    return new SortCondition({ attribute, direction })
  }

  static createFromString(sortString: string): SortConditionInterface[] {
    const sortings: SortConditionInterface[] = []
    const sortingPattern = /([\w.]+):(\w+)/g

    let sortingMatch

    while ((sortingMatch = sortingPattern.exec(sortString)) !== null) {
      sortings.push(
        new SortCondition({
          attribute: sortingMatch[1],
          direction: sortingMatch[2] as SortDirection,
        }),
      )
    }

    return sortings
  }
}

export default SortConditionBuilder
