import { createSchema } from "morphism"
import BaseSiteHealth, { SourceBaseSiteHealth } from "../_BaseSiteHealth"

export interface TargetSiteHealth {
  wordpress_version: string
  php_current_version: string
  php_recommended_version: string
  php_is_secure: boolean
  php_is_supported: boolean
  is_ssl: boolean
  is_indexable: boolean
  is_up_to_date: boolean
  defined_wp_debug: boolean
  total_db_optimization_expired_transient: number
  total_db_optimization_table: number
  total_db_optimization_spam_comment: number
  total_db_optimization_trashed_comment: number
  total_db_optimization_auto_draft_post: number
  total_db_optimization_post_revision: number
  total_db_optimization_trashed_post: number
}

export const SchemaSiteHealthModel = createSchema<
  TargetSiteHealth,
  SourceBaseSiteHealth
>({
  wordpress_version: "wordpress_version",
  php_current_version: "php_current_version",
  php_recommended_version: "php_recommended_version",
  php_is_secure: "php_is_secure",
  php_is_supported: "php_is_supported",
  is_ssl: "is_ssl",
  is_indexable: "is_indexable",
  is_up_to_date: "is_up_to_date",
  defined_wp_debug: "defined_wp_debug",
  total_db_optimization_expired_transient:
    "total_db_optimization_expired_transient",
  total_db_optimization_table: "total_db_optimization_table",
  total_db_optimization_spam_comment: "total_db_optimization_spam_comment",
  total_db_optimization_trashed_comment:
    "total_db_optimization_trashed_comment",
  total_db_optimization_auto_draft_post:
    "total_db_optimization_auto_draft_post",
  total_db_optimization_post_revision: "total_db_optimization_post_revision",
  total_db_optimization_trashed_post: "total_db_optimization_trashed_post",
})

class SiteHealthModel extends BaseSiteHealth implements TargetSiteHealth {
  getTotalPostDbOptimization(): number {
    return (
      this.total_db_optimization_auto_draft_post +
      this.total_db_optimization_post_revision +
      this.total_db_optimization_trashed_post
    )
  }

  getTotalCommentDbOptimization(): number {
    return (
      this.total_db_optimization_spam_comment +
      this.total_db_optimization_trashed_comment
    )
  }

  getTotalTableDbOptimization(): number {
    return this.total_db_optimization_table
  }

  getTotalTransientDbOptimization(): number {
    return this.total_db_optimization_expired_transient
  }

  getTotalOptimization(): number {
    return (
      this.getTotalPostDbOptimization() +
      this.getTotalCommentDbOptimization() +
      this.getTotalTableDbOptimization() +
      this.getTotalTransientDbOptimization()
    )
  }
}

export default SiteHealthModel
