import { System } from "@design-system/index"
import React from "react"
import { TableColumn, TableRowProcess } from "@design-system/Table/types"
import { useTable } from "@design-system/Table/hooks/useTable"
import { isNil } from "lodash"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import ReactTooltip from "react-tooltip"
import { WordPressCoreComponents } from "@components/WordPressCore/components"

interface Props {
  project: MainProjectsTableModel
  columns?: Array<TableColumn>
  columnsClassname?: string
}

export const ProjectDatabaseOptimizationRow = ({
  project,
  columns = [],
  columnsClassname,
}: Props) => {
  const { isRowProcessing, getRowProcessing } = useTable()

  const isInProcess = isRowProcessing({
    key: project.id,
  })

  let itemInProcess: TableRowProcess
  if (isInProcess) {
    itemInProcess = getRowProcessing({
      key: project.id,
    })
  }

  return (
    <System.Table.RowWithActions
      columns={columns}
      innerRowClassName={columnsClassname}
      rowKey={project.id}
      item={project}
    >
      <label
        htmlFor={`checkbox_${project.id}`}
        className="flex cursor-pointer items-center gap-4 truncate"
      >
        <System.Site.Preview
          site={{
            base_url: project.base_url,
            name: project.name,
          }}
        />
      </label>
      {isInProcess ? (
        <div className="col-span-4">
          <WordPressCoreComponents.TableRowProcessing
            item={{
              id: project.id,
            }}
            itemInProcess={itemInProcess}
            projectId={project.id}
          />
        </div>
      ) : (
        <>
          <div>
            {!isNil(
              project.warnings.total_db_optimization_expired_transient,
            ) ? (
              <>
                <System.Badges.Information
                  color={
                    project.warnings.getTotalPostDbOptimization() > 0
                      ? "red"
                      : "green"
                  }
                >
                  <div
                    className="flex items-center gap-2"
                    data-tip
                    data-for={`action_post_${project.id}`}
                  >
                    <System.Svg.Post className="h-4 w-4" />
                    {project.warnings.getTotalPostDbOptimization()}
                  </div>
                </System.Badges.Information>
                <ReactTooltip
                  id={`action_post_${project.id}`}
                  effect="solid"
                  place="top"
                >
                  <div>
                    <div>
                      Revision:{" "}
                      {project.warnings.total_db_optimization_post_revision}
                    </div>
                    <div>
                      Draft:{" "}
                      {project.warnings.total_db_optimization_auto_draft_post}
                    </div>
                    <div>
                      Trashed:{" "}
                      {project.warnings.total_db_optimization_trashed_post}
                    </div>
                  </div>
                </ReactTooltip>
              </>
            ) : (
              <System.Text>No data yet</System.Text>
            )}
          </div>

          <div>
            {!isNil(project.warnings.total_db_optimization_table) ? (
              <>
                <System.Badges.Information
                  color={
                    project.warnings.getTotalCommentDbOptimization() > 0
                      ? "red"
                      : "green"
                  }
                >
                  <div
                    className="flex items-center gap-2"
                    data-tip
                    data-for={`action_comment_${project.id}`}
                  >
                    <System.Svg.Message className="h-4 w-4" />
                    {project.warnings.getTotalCommentDbOptimization()}
                  </div>
                </System.Badges.Information>
                <ReactTooltip
                  id={`action_comment_${project.id}`}
                  effect="solid"
                  place="top"
                >
                  <div>
                    <div>
                      Spam:{" "}
                      {project.warnings.total_db_optimization_spam_comment}
                    </div>
                    <div>
                      Trashed:{" "}
                      {project.warnings.total_db_optimization_trashed_comment}
                    </div>
                  </div>
                </ReactTooltip>
              </>
            ) : (
              <System.Text>No data yet</System.Text>
            )}
          </div>

          <div>
            {!isNil(
              project.warnings.total_db_optimization_expired_transient,
            ) ? (
              <System.Badges.Information
                color={
                  project.warnings.total_db_optimization_expired_transient > 0
                    ? "red"
                    : "green"
                }
                className="gap-2"
              >
                <System.Svg.Tools className="h-4 w-4" />
                {project.warnings.total_db_optimization_expired_transient}
              </System.Badges.Information>
            ) : (
              <System.Text>No data yet</System.Text>
            )}
          </div>

          <div>
            {!isNil(project.warnings.total_db_optimization_table) ? (
              <System.Badges.Information
                color={
                  project.warnings.total_db_optimization_table > 0
                    ? "red"
                    : "green"
                }
                className="gap-2"
              >
                <System.Svg.Database className="h-4 w-4" />
                {project.warnings.total_db_optimization_table}
              </System.Badges.Information>
            ) : (
              <System.Text>No data yet</System.Text>
            )}
          </div>
          <div className="ml-auto">
            {project.warnings.getTotalOptimization() > 0 && (
              <WordPressCoreComponents.Action.DatabaseOptimization
                projectId={project.id}
                databaseOptimization={{
                  revision:
                    project.warnings.total_db_optimization_post_revision,
                  autoDraft:
                    project.warnings.total_db_optimization_auto_draft_post,
                  trashPost:
                    project.warnings.total_db_optimization_trashed_post,
                  spamComment:
                    project.warnings.total_db_optimization_spam_comment,
                  trashComment:
                    project.warnings.total_db_optimization_trashed_comment,
                  expiredTransient:
                    project.warnings.total_db_optimization_expired_transient,
                  table: project.warnings.total_db_optimization_table,
                }}
              />
            )}
          </div>
        </>
      )}
    </System.Table.RowWithActions>
  )
}
