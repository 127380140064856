import HasSSL from "./HasSSL"
import IsIndexable from "./IsIndexable"
import PhpVersionIsSupported from "./PhpVersionIsSupported"
import PluginsInactive from "./PluginsInactive"
import PluginVulnerabilities from "./PluginVulnerabilities"
import ThemesInactive from "./ThemesInactive"
import ThemeVulnerabilities from "./ThemeVulnerabilities"
import WordPressIsUpToDate from "./WordPressIsUpToDate"
import WordPressVulnerabilities from "./WordPressVulnerabilities"
import WPDebugMode from "./WPDebugMode"

export const SecurityComplianceCheckSpecifications = {
  has_ssl: HasSSL,
  is_indexable: IsIndexable,
  wp_is_up_to_date: WordPressIsUpToDate,
  php_version_is_supported: PhpVersionIsSupported,
  plugins_inactive: PluginsInactive,
  themes_inactive: ThemesInactive,
  wp_debug_is_defined: WPDebugMode,
  plugin_vulnerabilities: PluginVulnerabilities,
  theme_vulnerabilities: ThemeVulnerabilities,
  wordpress_vulnerabilities: WordPressVulnerabilities,
}
