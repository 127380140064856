import { ProjectsBulkAction } from "./BulkAction"
import { FetchManyProjects, FetchOneProject } from "./Fetch"
import { ProjectsFilters } from "./Filter"
import { ProjectSelector } from "./ProjectSelector"
import { ProjectsTable } from "./Table"

export const ProjectComponents = {
  Table: ProjectsTable,
  BulkAction: ProjectsBulkAction,
  FetchOne: FetchOneProject,
  FetchMany: FetchManyProjects,
  Filter: ProjectsFilters,
  ProjectSelector: ProjectSelector,
}
