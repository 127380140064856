import { ReactNode } from "react"
import { KeyedMutator } from "swr"
import { useProjectWordPressThemes } from "@components/Project/hooks/wordpress/useProjectWordPressThemes"
import ProjectThemeModel from "@/Theme/models/ProjectThemeModel"
import { JSONProjectThemeApiData } from "@/Theme/types/theme-models"

interface PropsChildren {
  error: any
  mutate: KeyedMutator<{
    code: string
    data: JSONProjectThemeApiData[]
  }>
  isValidating: boolean
  isLoading: boolean
  data: ProjectThemeModel[]
}

interface Props {
  children: (props: PropsChildren) => ReactNode
  projectId: number
  isImmutable?: boolean
}

export function FetchOneProjectThemeData({ projectId, children }: Props) {
  const response = useProjectWordPressThemes({
    projectId: projectId,
  })

  return (
    <>
      {children({
        ...response,
      })}
    </>
  )
}
