import LabelLinkProjectModel from "@/Core/Models/Labels/Projects/model"
import useNotifications from "@hooks/notifications/useNotifications"
import { mutate } from "swr"
import { System } from "@design-system/index"
import { useTable } from "@design-system/Table/hooks/useTable"
import { useRouter } from "next/router"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import {
  JSONAlertNotification,
  JSONOneAlertNotification,
} from "@/AlertingNotification/types/alert-notification-models"
import { FetcherAlertingNotification } from "@/AlertingNotification/Fetcher"
import { getPrivateApiAlertNotificationsPath } from "@/AlertingNotification/api/routes-private"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { NotificationComponents } from "@components/Notification/components"
import { IBulkAction } from "@design-system/Content/BulkActions/List"

interface Props {
  projects: LabelLinkProjectModel[] | MainProjectsTableModel[]
  notification: JSONOneAlertNotification | JSONAlertNotification
}

export const NotificationSitesSelector = ({
  projects,
  notification,
}: Props) => {
  const { state, clearCheckedRows } = useTable()
  const { openModal } = useGenericModal()
  const { push } = useRouter()
  const { checkedRows } = state
  const { handleNotifySuccess } = useNotifications()

  const selectedProjects = Object.values(projects).filter((project) => {
    return checkedRows.has(project.id)
  })

  const handleLinkLabelToSites = async () => {
    await FetcherAlertingNotification.linkSites({
      notificationId: notification.id,
      projectIds: Array.from(checkedRows).map((id) => Number(id)),
    })

    mutate(getPrivateApiAlertNotificationsPath())

    clearCheckedRows()

    handleNotifySuccess({
      message: "Sites linked successfully",
    })

    push("/notifications")
  }

  const handleUnlinkLabelToSites = async () => {
    await FetcherAlertingNotification.unlinkSites({
      notificationId: notification.id,
      projectIds: Array.from(checkedRows).map((id) => Number(id)),
    })

    mutate(getPrivateApiAlertNotificationsPath())

    clearCheckedRows()

    handleNotifySuccess({
      message: "Sites unlinked successfully",
    })

    push("/notifications")
  }

  const actions = [
    {
      label: "Assign to selection",
      icon: <System.Svg.Check className="h-4 w-4 opacity-50" />,
      onClick: () => {
        openModal({
          component: (
            <NotificationComponents.Modal.ConfirmLinkNotificationToSites
              notification={{
                name: notification.name,
              }}
              totalProjects={selectedProjects.length}
              onConfirm={handleLinkLabelToSites}
            />
          ),
        })
      },
    },
    {
      label: "Unassign to selection",
      icon: <System.Svg.Cross className="h-4 w-4 opacity-50" />,
      onClick: () => {
        openModal({
          component: (
            <NotificationComponents.Modal.ConfirmUnlinkNotificationToSites
              notification={{
                name: notification.name,
              }}
              totalProjects={selectedProjects.length}
              onConfirm={handleUnlinkLabelToSites}
            />
          ),
        })
      },
      isDisabled() {
        return !selectedProjects.some((project) => {
          return project.notifications.some((notification) => {
            return notification.id === notification.id
          })
        })
      },
    },
  ] as IBulkAction[]

  return (
    <>
      <System.Table.BulkActions.List
        actions={actions}
        isShowing={checkedRows.size > 0}
      />
    </>
  )
}
