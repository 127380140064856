import { ProjectThemes } from ".prisma/client"
import { reduce } from "lodash"

interface Props {
  themes: Array<{ version: string; latest_version: string } | ProjectThemes>
}

export const getCountThemeNeedUpdate = ({ themes }: Props) => {
  return reduce(
    themes,
    (sum, item) => {
      if (item.version != item.latest_version) {
        return sum + 1
      }
      return sum
    },
    0,
  )
}
