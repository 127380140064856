import { AttributesSortConditionInterface } from "@/Services/Search/dependencies/SortConditionInterface"
import FilterContext from "@/Services/Search/FilterContext"
import SortConditionBuilder from "@/Services/Search/SortConditionBuilder"
import SortContext from "@/Services/Search/SortContext"
import {
  filterReducer,
  StateFilter,
} from "@components/Filters/reducers/filterReducer"
import useLocalStorage from "@hooks/useLocalStorage"
import React, { createContext, useEffect, useReducer } from "react"

const FILTER_PROJECTS_SORT_KEY = "filter_projects_sort"

const initialState: StateFilter = {
  filters: new FilterContext(),
  search: "",
  sort: null,
}

export const FilterProjectsContext = createContext<{
  state: StateFilter
  dispatch: React.Dispatch<any>
}>(null)

function FilterProjectsContextProvider({ children }) {
  const [defaultSortStorage, setDefaultSortStorage] = useLocalStorage<
    AttributesSortConditionInterface<any>
  >(FILTER_PROJECTS_SORT_KEY, {
    attribute: "id",
    direction: "desc",
  })

  const initSort = new SortContext().addCondition(
    SortConditionBuilder.create(defaultSortStorage),
  )

  const [state, dispatch] = useReducer(filterReducer, {
    ...initialState,
    sort: initSort,
  })

  useEffect(() => {
    setDefaultSortStorage(state.sort.getCondition(0))
  }, [state.sort])

  return (
    <FilterProjectsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </FilterProjectsContext.Provider>
  )
}

export { FilterProjectsContextProvider }
