import useSWR from "swr"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { getPrivateApiMeSearchTenantTokenPath } from "@/Profile/api/routes-private"

type Data = {
  search_user_token: string
}

interface Props {
  suspense?: boolean
  options?: {
    refreshInterval?: number
  }
}

export const useMeSearchUserToken = (
  { suspense, options }: Props = {
    suspense: true,
  },
) => {
  const { data: response, ...rest } = useSWR<{
    code: string
    data: Data
  }>(getPrivateApiMeSearchTenantTokenPath(), {
    suspense: suspense,
    fetcher: fetcherPrivateApiUmbrella(),
    ...options,
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: null,
      ...rest,
    }
  }

  return {
    data: data,
    ...rest,
  }
}
