import useSWR, { BareFetcher } from "swr"
import qs from "query-string"
import { getPrivateApiOneProjectWordPressPluginsPath } from "@/Project/api/routes-private"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { JSONProjectPluginApiData } from "@/Plugin/types/plugin-from-api"
import { PublicConfiguration } from "swr/_internal"
import useSWRImmutable from "swr/immutable"
import { isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import ProjectPluginModel, {
  SchemaJSONProjectPluginApiDataToProjectPluginModel,
} from "@/Plugin/models/ProjectPluginModel"

interface Props {
  projectId: number
  options?: Partial<
    PublicConfiguration<
      {
        code: string
        data: JSONProjectPluginApiData[]
      },
      any,
      BareFetcher<{
        code: string
        data: JSONProjectPluginApiData[]
      }>
    >
  >
  isImmutable?: boolean
}

export const useProjectWordPressPlugins = ({
  projectId,
  options,
  isImmutable = false,
}: Props) => {
  const url = qs.stringifyUrl({
    url: getPrivateApiOneProjectWordPressPluginsPath({
      projectId: projectId,
    }),
  })

  const callback = isImmutable ? useSWRImmutable : useSWR

  const { data: response, ...rest } = callback<{
    code: string
    data: JSONProjectPluginApiData[]
  }>(url, {
    ...options,
    suspense: isNil(options?.suspense) ? true : options.suspense,
    fetcher: fetcherPrivateApiUmbrella(),
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: [],
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONProjectPluginApiDataToProjectPluginModel,
      data,
      ProjectPluginModel,
    ),
    ...rest,
  }
}
