import FilterContext from "@/Services/Search/FilterContext"
import SortContext from "@/Services/Search/SortContext"

export type StateFilter = {
  filters: FilterContext
  search?: string
  sort?: SortContext
}

export function filterReducer(state: StateFilter, action) {
  switch (action.type) {
    case "SET_FILTERS":
      return { ...state, filters: action.filters }
    case "SET_SEARCH":
      return { ...state, search: action.search }
    case "ADD_FILTER_CONDITION": {
      const newFilter = new FilterContext([...state.filters.conditions])
      newFilter.addCondition(action.condition)
      return { ...state, filters: newFilter }
    }
    case "REMOVE_FILTER_CONDITION": {
      const updatedFilter = new FilterContext(
        state.filters.conditions.filter(
          (condition) => condition !== action.condition,
        ),
      )
      return { ...state, filters: updatedFilter }
    }
    case "REMOVE_MANY_FILTER_CONDITIONS": {
      const updatedFilterMany = new FilterContext(
        state.filters.conditions.filter(
          (condition) => !action.conditions.includes(condition),
        ),
      )
      return { ...state, filters: updatedFilterMany }
    }

    case "CLEAR_FILTER_CONDITIONS":
      return { ...state, filters: new FilterContext() }
    case "SET_SORT":
      return { ...state, sort: action.sort }

    case "ADD_SORT_CONDITION": {
      const newSort = new SortContext([...state.sort.conditions])
      newSort.addCondition(action.condition)
      return { ...state, sort: newSort }
    }
    case "REMOVE_SORT_CONDITION": {
      const updatedSort = new SortContext(
        state.sort.conditions.filter(
          (condition) => condition !== action.condition,
        ),
      )
      return { ...state, sort: updatedSort }
    }
    default:
      return state
  }
}
