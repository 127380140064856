import { TypeGenericFilteringItemsTabs } from "@design-system/Context/GenericFilteringItemsWithTabs"
import { useGenericFilteringItemsWithTabs } from "@design-system/Context/GenericFilteringItemsWithTabs/hooks/useGenericFilteringItemsWithTabs"
import { System } from "@design-system/index"
import React from "react"

interface Props<DataFiltering> {
  filteringCallback: (props: {
    data: DataFiltering[]
    filters: { query: string; tab: TypeGenericFilteringItemsTabs }
  }) => DataFiltering[]
  items?: Array<{
    label: string
    value: TypeGenericFilteringItemsTabs
  }>
}

export function TabsFilteringProjects<DataFiltering = any>({
  filteringCallback,
  items = [],
}: Props<DataFiltering>) {
  const { state, handleChangeTab } = useGenericFilteringItemsWithTabs()

  return (
    <>
      <div className="flex w-full items-center justify-between gap-2">
        <System.Tab.Navigation>
          {items.map((tab) => {
            const data = filteringCallback({
              data: state.data,
              filters: {
                query: state.query,
                tab: tab.value,
              },
            })

            return (
              <System.Tab.Button
                count={data.length}
                key={tab.value}
                className="text-gray-500 hover:text-gray-700"
                isCurrent={state.tab === tab.value}
                onClick={() => {
                  handleChangeTab({
                    value: tab.value,
                  })
                }}
              >
                {tab.label}
              </System.Tab.Button>
            )
          })}
        </System.Tab.Navigation>
      </div>
    </>
  )
}
