import { getPrivateApiOneProjectWordPressSiteHealthPath } from "@/Project/api/routes-private"
import SiteHealthModel, {
  SchemaJSONSiteHealthApiDataToSiteHealthModel,
} from "@/WordPressCore/models/SiteHealthModel"

import { JSONSiteHealthApiData } from "@/WordPressCore/types/api-types"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import qs from "query-string"
import { BareFetcher, PublicConfiguration } from "swr/_internal"
import useSWRImmutable from "swr/immutable"

interface Props {
  projectId: number
  suspense?: boolean
  options?: Partial<
    PublicConfiguration<
      {
        data: JSONSiteHealthApiData
        code: string
      },
      any,
      BareFetcher<{
        data: JSONSiteHealthApiData
        code: string
      }>
    >
  >
}

export const useProjectWordPressSiteHealth = ({
  projectId,
  options,
}: Props) => {
  const url = qs.stringifyUrl({
    url: getPrivateApiOneProjectWordPressSiteHealthPath({
      projectId: projectId,
    }),
  })

  const { data: response, ...rest } = useSWRImmutable<{
    data: JSONSiteHealthApiData
    code: string
  }>(url, {
    ...options,
    suspense: true,
    fetcher: fetcherPrivateApiUmbrella(),
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: {} as SiteHealthModel,
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONSiteHealthApiDataToSiteHealthModel,
      data,
      SiteHealthModel,
    ),
    ...rest,
  }
}
