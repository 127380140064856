import { getMaintenanceReportLinkProjectsPath } from "."

export interface Props {
  templateId: number
  projects: number[]
}

export const linkProjectsMaintenanceReportTemplate = async ({
  templateId,
  projects,
}: Props) => {
  try {
    const result = await fetch(getMaintenanceReportLinkProjectsPath(), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        templateId: templateId,
        projects: projects,
      }),
    })

    return await result.json()
  } catch (error) {
    return null
  }
}
