import { ProjectWarnings } from "@prisma/client"

export interface SourceBaseSiteHealth extends ProjectWarnings {}

class BaseSiteHealth {
  wordpress_version: string
  php_current_version: string
  php_recommended_version: string
  php_is_secure: boolean
  php_is_supported: boolean
  is_ssl: boolean
  is_indexable: boolean
  is_up_to_date: boolean
  defined_wp_debug: boolean
  total_db_optimization_expired_transient: number
  total_db_optimization_table: number
  total_db_optimization_spam_comment: number
  total_db_optimization_trashed_comment: number
  total_db_optimization_auto_draft_post: number
  total_db_optimization_post_revision: number
  total_db_optimization_trashed_post: number
}

export default BaseSiteHealth
