interface Props {
  theme: {
    is_active: boolean
    stylesheet: string
  }
  activeTheme: {
    template: string
  }
}

export const isThemeInactive = ({ theme, activeTheme }: Props) => {
  return (
    !theme.is_active &&
    theme.stylesheet !== activeTheme.template && // A parent theme is not inactive
    theme.stylesheet !== "twentytwentythree" && // Twenty Twenty Three is not inactive
    theme.stylesheet !== "twentytwentyfour" // Twenty Twenty Four is not inactive
  )
}
