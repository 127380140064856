import { isNil } from "lodash"
import { getMaintenanceReportTemplatesPath } from "."

export interface Props {
  data: {
    title: string
    template_name?: string
    language: string
    color: string
    logo: string
    date_format: string
    time_format: string
  }
}

export const createMaintenanceReportTemplate = async (props: Props) => {
  try {
    const body = new FormData()

    Object.keys(props.data).forEach((key) => {
      const value = props.data[key]

      if (!isNil(value)) {
        body.append(key, value)
      }
    })

    const response = await fetch(getMaintenanceReportTemplatesPath(), {
      method: "POST",
      body: body,
    })

    return await response.json()
  } catch (error) {
    console.log("[error createMaintenanceReportTemplate]", error)
    return null
  }
}
