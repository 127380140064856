import { AttributesFilterConditionInterface } from "@/Services/Search/dependencies/FilterConditionInterface"
import { AttributesSortConditionInterface } from "@/Services/Search/dependencies/SortConditionInterface"
import FilterConditionBuilder from "@/Services/Search/FilterConditionBuilder"
import SortConditionBuilder from "@/Services/Search/SortConditionBuilder"
import SortContext from "@/Services/Search/SortContext"
import { StateFilter } from "@components/Filters/reducers/filterReducer"
import { isEmpty } from "lodash"
import { useContext } from "react"

interface Props<StateFilter> {
  context: React.Context<{
    state: StateFilter
    dispatch: React.Dispatch<any>
  }>
}

export function useFilters<FilterAttributes, SortAttributes>({
  context,
}: Props<StateFilter>) {
  const { state, dispatch } = useContext<{
    state: StateFilter
    dispatch: React.Dispatch<any>
  }>(context)

  function setFilters(filters) {
    dispatch({ type: "SET_FILTERS", filters })
  }

  function addFilterCondition({
    attribute,
    operator,
    value,
  }: AttributesFilterConditionInterface<FilterAttributes>) {
    const condition = FilterConditionBuilder.create({
      attribute,
      operator,
      value,
    })
    dispatch({ type: "ADD_FILTER_CONDITION", condition })
  }

  function removeManyFilterConditions(conditions) {
    dispatch({ type: "REMOVE_MANY_FILTER_CONDITIONS", conditions })
  }

  function removeOneFilterCondition(condition) {
    dispatch({ type: "REMOVE_FILTER_CONDITION", condition })
  }

  function clearFilterConditions() {
    dispatch({ type: "CLEAR_FILTER_CONDITIONS" })
  }
  function setSort({
    attribute,
    direction,
  }: AttributesSortConditionInterface<SortAttributes>) {
    const sort = new SortContext().addCondition(
      SortConditionBuilder.create({
        attribute,
        direction,
      }),
    )

    dispatch({ type: "SET_SORT", sort })
  }

  function addSortCondition({
    attribute,
    direction,
  }: AttributesSortConditionInterface<SortAttributes>) {
    const condition = SortConditionBuilder.create({
      attribute,
      direction,
    })
    dispatch({ type: "ADD_SORT_CONDITION", condition })
  }

  function removeSortCondition(condition) {
    dispatch({ type: "REMOVE_SORT_CONDITION", condition })
  }

  function setSearchQuery(search) {
    dispatch({ type: "SET_SEARCH", search })
  }

  function hasAtLeastOneFilter() {
    return state.filters.getConditions().length > 0 || !isEmpty(state.search)
  }

  return {
    state,
    setFilters,
    addFilterCondition,
    removeOneFilterCondition,
    removeManyFilterConditions,
    clearFilterConditions,
    setSort,
    addSortCondition,
    removeSortCondition,
    setSearchQuery,
    hasAtLeastOneFilter,
  }
}
