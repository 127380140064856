import useSWR from "swr"
import qs from "query-string"
import { getPrivateApiOneProjectWordPressThemesPath } from "@/Project/api/routes-private"
import { isEmpty, isNil } from "lodash"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { morphism } from "morphism"
import ProjectThemeModel, {
  SchemaJSONProjectThemeApiDataToProjectThemeModel,
} from "@/Theme/models/ProjectThemeModel"

import { BareFetcher, PublicConfiguration } from "swr/_internal"
import { JSONProjectThemeApiData } from "@/Theme/types/theme-models"

interface Props {
  projectId: number
  queryParams?: {
    refresh?: number
  }
  suspense?: boolean
  options?: Partial<
    PublicConfiguration<
      {
        code: string
        data: JSONProjectThemeApiData[]
      },
      any,
      BareFetcher<{
        code: string
        data: JSONProjectThemeApiData[]
      }>
    >
  >
}

export function useProjectWordPressThemes({
  projectId,
  suspense = true,
  options = {},
}: Props) {
  const url = qs.stringifyUrl({
    url: getPrivateApiOneProjectWordPressThemesPath({
      projectId: projectId,
    }),
  })

  const { data: response, ...rest } = useSWR<{
    code: string
    data: JSONProjectThemeApiData[]
  }>(url, {
    suspense: suspense,
    fetcher: fetcherPrivateApiUmbrella(),
    ...options,
  })

  const { code, data } = response || {}
  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: [],
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONProjectThemeApiDataToProjectThemeModel,
      data,
      ProjectThemeModel,
    ),
    ...rest,
  }
}
