import dynamic from "next/dynamic"
import { System } from "@design-system/index"

import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { ProjectMaintenanceReportTemplateRow } from "./Row"
import MaintenanceReportTemplatesTableModel from "@/Core/Models/MaintenanceReportTemplates/MaintenanceReportTemplatesTableModel"

const DynamicMaintenanceReportTemplateSelector = dynamic(
  () => import("../../BulkAction/MaintenanceReportTemplateSelector"),
)

interface Props {
  projects: MainProjectsTableModel[]
  template: MaintenanceReportTemplatesTableModel
  onBulkSuccess?: () => void
}

const COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "existing",
    name: "Existing Template",
  },
]

export const TableProjectMaintenanceReportTemplate = ({
  projects,
  template,
  onBulkSuccess = () => {},
}: Props) => {
  return (
    <System.Table.List<MainProjectsTableModel>
      columns={COLUMNS}
      rows={projects}
      columnsClassname="grid-cols-[auto_1fr_1fr]"
      withChecked
      bulkActions={
        <DynamicMaintenanceReportTemplateSelector
          projects={projects}
          template={template}
          onSuccess={onBulkSuccess}
        />
      }
    >
      {({ item, columnsClassname }) => {
        return (
          <>
            <ProjectMaintenanceReportTemplateRow
              project={item}
              columnsClassname={columnsClassname}
            />
          </>
        )
      }}
    </System.Table.List>
  )
}
