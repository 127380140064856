import { System } from "@design-system/index"
import React from "react"
import { TableColumn } from "@design-system/Table/types"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { isNil, reduce, uniq } from "lodash"
import { hasBackupEvent } from "@/AlertingNotification/models/functions/hasBackupEvent"
import { hasPerformanceEvent } from "@/AlertingNotification/models/functions/hasPerformanceEvent"
import { hasSecurityEvent } from "@/AlertingNotification/models/functions/hasSecurityEvent"
import ReactTooltip from "react-tooltip"
import { getTitleNotificationEventType } from "@/AlertingNotification/models/functions/getTitleNotificationEventType"
import { NotificationEventType } from "@prisma/client"

interface Props {
  project: MainProjectsTableModel
  columns?: Array<TableColumn>
  columnsClassname?: string
}

export const ProjectNotificationRow = ({
  project,
  columns = [],
  columnsClassname,
}: Props) => {
  const getAllNotifications = () => {
    return reduce(
      project.notifications,
      (acc, notification) => {
        if (
          !acc.channel.includes("email") &&
          !isNil(notification.emailNotificationChannelId)
        ) {
          acc.channel.push("email")
        }

        if (
          !acc.channel.includes("slack") &&
          !isNil(notification.slackNotificationChannelId)
        ) {
          acc.channel.push("slack")
        }

        return {
          eventTypes: uniq([...acc.eventTypes, ...notification.eventTypes]),
          channel: [...acc.channel],
        }
      },
      {
        channel: [],
        eventTypes: [],
      },
    )
  }

  const allNotifications = getAllNotifications()

  return (
    <System.Table.RowWithActions
      columns={columns}
      innerRowClassName={columnsClassname}
      rowKey={project.id}
      item={project}
    >
      <label
        htmlFor={`checkbox_${project.id}`}
        className="flex cursor-pointer items-center gap-4 truncate"
      >
        <System.Site.Preview
          site={{
            base_url: project.base_url,
            name: project.name,
          }}
        />
      </label>

      <div>
        <div className="flex items-center gap-2">
          {allNotifications.channel.includes("slack") && (
            <div className="rounded-full bg-gray-900 p-1">
              <System.Svg.SlackUncolor className="h-7 w-7 text-white" />
            </div>
          )}
          {allNotifications.channel.includes("email") && (
            <div className="rounded-full bg-gray-900 p-1">
              <System.Svg.Email
                className="h-7 w-7 p-1 text-white"
                strokeWidth={1.5}
              />
            </div>
          )}
          {project.notifications.length > 0 && (
            <div className="ml-2 flex items-center gap-2 rounded-full border bg-white px-2">
              {hasBackupEvent({
                eventTypes: allNotifications.eventTypes,
              }) && (
                <>
                  <div data-tip data-for={`eventType_${project.id}_backup`}>
                    <System.Svg.Backup className="h-8 w-8 p-1" />
                  </div>
                  <ReactTooltip
                    id={`eventType_${project.id}_backup`}
                    effect="solid"
                    place="top"
                  >
                    {allNotifications.eventTypes.map(
                      (eventType: NotificationEventType, key) => {
                        if (
                          !hasBackupEvent({
                            eventTypes: [eventType],
                          })
                        ) {
                          return null
                        }
                        return (
                          <div key={`${eventType}_${key}`}>
                            {getTitleNotificationEventType(eventType)}
                          </div>
                        )
                      },
                    )}
                  </ReactTooltip>
                </>
              )}
              {hasPerformanceEvent({
                eventTypes: allNotifications.eventTypes,
              }) && (
                <>
                  <div
                    data-tip
                    data-for={`eventType_${project.id}_performance`}
                  >
                    <System.Svg.Monitoring className="h-8 w-8 p-1" />
                  </div>
                  <ReactTooltip
                    id={`eventType_${project.id}_performance`}
                    effect="solid"
                    place="top"
                  >
                    {allNotifications.eventTypes.map(
                      (eventType: NotificationEventType, key) => {
                        if (
                          !hasPerformanceEvent({
                            eventTypes: [eventType],
                          })
                        ) {
                          return null
                        }
                        return (
                          <div key={`${eventType}_${key}`}>
                            {getTitleNotificationEventType(eventType)}
                          </div>
                        )
                      },
                    )}
                  </ReactTooltip>
                </>
              )}
              {hasSecurityEvent({
                eventTypes: allNotifications.eventTypes,
              }) && (
                <>
                  <div data-tip data-for={`eventType_${project.id}_security`}>
                    <System.Svg.Shield className="h-8 w-8 p-1" />
                  </div>
                  <ReactTooltip
                    id={`eventType_${project.id}_security`}
                    effect="solid"
                    place="top"
                  >
                    {allNotifications.eventTypes.map(
                      (eventType: NotificationEventType, key) => {
                        if (
                          !hasSecurityEvent({
                            eventTypes: [eventType],
                          })
                        ) {
                          return null
                        }
                        return (
                          <div key={`${eventType}_${key}`}>
                            {getTitleNotificationEventType(eventType)}
                          </div>
                        )
                      },
                    )}
                  </ReactTooltip>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center gap-2">
          {project.notifications.map((notification) => {
            return <div key={notification.id}>{notification.name}</div>
          })}
        </div>
      </div>
    </System.Table.RowWithActions>
  )
}
