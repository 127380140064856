import React from "react"
import { ProcessTasksType } from "@prisma/client"
import { System } from "@design-system/index"
import { useTable } from "@design-system/Table/hooks/useTable"
import { IBulkAction } from "@design-system/Table/components/BulkActions/List"
import { useGenericStep } from "@design-system/Context/GenericStep/hooks/useGenericStep"
import {
  KEY_STEP_DATABASE_OPTIMIZATION,
  STEP_CHOOSE_DATABASE_OPTIMIZATION,
} from "@components/WordPressCore/constants/StepDatabaseOptimization"
import MainProjectsTableModel from "@/Core/Models/Projects/MainProjectsTableModel"
import { isEmpty, isNil, reduce } from "lodash"

interface Props {
  projects?: MainProjectsTableModel[]
}

export function BulkActionDatabaseOptimization({ projects }: Props) {
  const { state } = useTable()
  const { setCurrentStep } = useGenericStep()

  const { checkedRows } = state

  if (checkedRows.size === 0) {
    return null
  }

  const ACTIONS = [
    {
      key: ProcessTasksType.UPDATE_CORE,
      label: (
        <>
          <System.Svg.Database className="h-4 w-4" />
          Choose your optimizations
        </>
      ),
      isDisabled() {
        const data = Array.from(checkedRows)
          .map((keyItem) => {
            const item = projects.find(
              (projectPlugin) => projectPlugin.id === keyItem,
            )

            if (isNil(item)) {
              return
            }

            if (item.warnings.getTotalOptimization() === 0) {
              return
            }

            return item
          })
          .filter((item) => !isNil(item))

        return isEmpty(data)
      },
      onClick: () => {
        const projectsAuthorized = Array.from(checkedRows)
          .map((keyItem) => {
            const item = projects.find(
              (projectPlugin) => projectPlugin.id === keyItem,
            )

            if (isNil(item)) {
              return
            }

            if (item.warnings.getTotalOptimization() === 0) {
              return
            }

            return item
          })
          .filter((item) => !isNil(item))

        const data = {
          projectIds: reduce(
            projectsAuthorized,
            (result, project) => {
              result.push(project.id)
              return result
            },
            [],
          ),
          databaseOptimization: reduce(
            projectsAuthorized,
            (result, project) => {
              result.revision +=
                project.warnings.total_db_optimization_post_revision
              result.autoDraft +=
                project.warnings.total_db_optimization_auto_draft_post
              result.trashPost +=
                project.warnings.total_db_optimization_trashed_post
              result.spamComment +=
                project.warnings.total_db_optimization_spam_comment
              result.trashComment +=
                project.warnings.total_db_optimization_trashed_comment
              result.expiredTransient +=
                project.warnings.total_db_optimization_expired_transient
              result.table += project.warnings.total_db_optimization_table

              return result
            },
            {
              revision: 0,
              autoDraft: 0,
              trashPost: 0,
              spamComment: 0,
              trashComment: 0,
              expiredTransient: 0,
              table: 0,
            },
          ),
        }

        setCurrentStep({
          key: KEY_STEP_DATABASE_OPTIMIZATION,
          current: STEP_CHOOSE_DATABASE_OPTIMIZATION,
          data: data,
        })
      },
    },
  ] as IBulkAction[]

  return (
    <>
      <System.Table.BulkActions.List
        actions={ACTIONS}
        isShowing={checkedRows.size > 0}
      />
    </>
  )
}
