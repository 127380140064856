import { getCountPluginNeedUpdate } from "@helpers/plugins/getCountPluginNeedUpdate"
import { getCountThemeNeedUpdate } from "@helpers/themes/getCountThemeNeedUpdate"
import { BackupFrequency, ProjectPlugins, ProjectThemes } from "@prisma/client"
import { get } from "lodash"
import { createSchema, morphism } from "morphism"
import SiteHealthModel, {
  SchemaSiteHealthModel,
} from "../../../SiteHealth/database/SiteHealthModel"
import { TargetMainProjectsTable } from "../../MainProjectsTableModel"
import { SourceBaseProject } from "../../_BaseProject"

export interface SourceMainProjectsTableDatabase extends SourceBaseProject {
  MaintenanceCustomer: {
    id: string
    company_name: string
    firstname: string
    lastname: string
    website_url: string
  }
  ProjectBackupSetting: {
    active: boolean
    frequency: BackupFrequency
  }
  ScheduledBackup: Array<{
    date_schedule: string
    id: number
  }>
}
export const SchemaMainProjectsTableDatabase = createSchema<
  TargetMainProjectsTable,
  SourceMainProjectsTableDatabase
>({
  id: "id",
  is_multisite: "is_multisite",
  name: "name",
  base_url: "base_url",
  wp_token: "wp_token",
  userId: "userId",
  hosting: "hosting",
  latest_downtime: "latest_downtime",
  latest_ping: "latest_ping",
  latest_performance_score: "latest_performance_score",
  count_warnings: "count_warnings",
  count_php_issues: "count_php_issues",
  created_at: "created_at",
  projectBackupSettingId: "projectBackupSettingId",
  projectMaintenanceReportSettingId: "projectMaintenanceReportSettingId",
  projectFtpSettingId: "projectFtpSettingId",
  projectDatabaseSettingId: "projectDatabaseSettingId",
  maintenanceCustomerId: "maintenanceCustomerId",
  is_currently_down: "is_currently_down",
  maintenanceCustomer: "MaintenanceCustomer",
  plugins: "ProjectPlugins",
  starred: "starred",
  labels: "labels",
  themes: "ProjectThemes",
  vulnerabilities: "vulnerabilities",
  output_ip: "output_ip",
  total_plugins_need_update: {
    path: "ProjectPlugins",
    fn: (plugins: ProjectPlugins[]) => {
      return getCountPluginNeedUpdate({
        plugins,
      })
    },
  },
  total_themes_need_update: {
    path: "ProjectThemes",
    fn: (themes: ProjectThemes[]) => {
      return getCountThemeNeedUpdate({
        themes,
      })
    },
  },
  is_up_to_date: "ProjectWarnings.is_up_to_date",
  warnings: {
    path: "ProjectWarnings",
    fn: (warnings) => {
      return morphism(SchemaSiteHealthModel, warnings, SiteHealthModel)
    },
  },
  backup: (source) => {
    return {
      settings: source.ProjectBackupSetting,
      next_schedule: get(source, "ScheduledBackup[0].date_schedule"),
      last_backup: new Date().toString(),
    }
  },
  last_synchronization: "last_synchronization",
  notifications: "ProjectNotifications",
  maintenanceReportTemplate: "ProjectMaintenanceReportTemplate",
})
